import React, { useState } from 'react';
import moment from 'moment-timezone';
import dayjs from 'dayjs';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  FormControl,
  InputAdornment,
  IconButton,
  Modal,
  Button,
  Grid
} from '@mui/material';
import TableCell from "@material-ui/core/TableCell";
import Input from "@material-ui/core/Input";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { withStyles } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const ButtonCustom = withStyles({
  root: {
    borderRadius: '8px',
    '&:hover': {
      borderRadius: '8px',
    },
  },
})(Button);

export const CustomTableCellTime = ({ row, name, onChange }) => {
  const classes = useStyles();
  const { isEditMode } = row;
  const [open, setOpen] = useState(false);
  const [valueTime, setValueTime] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeTime = (newValue) => {
    setValueTime(newValue);
  }

  const handleOK = () => {
    onChange(valueTime, row, name);
    setOpen(false);
  }

  const formatTime = moment(row[name], 'HH:mm:ss').format('YYYY-MM-DD[T]HH:mm');

  return (
    <TableCell align="left" className={classes.tableCell}>
      {isEditMode ? (
        <>
          <FormControl sx={{ mb: '5px', width: '130px' }} variant="standard" size="small" onFocus={handleOpen}>
            <Input
              inputProps={{ style: { fontSize: '14px', marginBottom: '-5px' }}}
              id="standard-adornment-time"
              type='text'
              value={row[name] ? moment(row[name], "hh:mm:ss").format("HH:mm") : ''}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle time visibility"
                  >
                    <AccessTimeIcon sx={{ fontSize: '16px' }} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Modal
            open={open}
            disableAutoFocus
            disableEnforceFocus
            onClose={handleClose}
          >
            <Grid container>
              <Grid item style={{ ...style }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['StaticTimePicker']}>
                      <StaticTimePicker
                        defaultValue={row[name] ? dayjs(formatTime) : ''}
                        name={name}
                        onChange={(newValue) => handleChangeTime(newValue)}
                        slotProps={{
                          actionBar: {
                            actions: [],
                          },
                        }}
                      />
                    </DemoContainer>
                </LocalizationProvider>
                <div style={{ background: 'white', padding: '8px', display: 'flex', justifyContent: 'flex-end' }}>
                  <ButtonCustom onClick={handleClose}>Cancel</ButtonCustom>
                  <ButtonCustom onClick={handleOK}>Ok</ButtonCustom>
                </div>
              </Grid>
            </Grid>
          </Modal>
        </>
      ) : (
        row[name] ? moment(row[name], "hh:mm:ss").format("HH:mm") : ''
      )}
    </TableCell>
  );
};

export const CustomTableCell = ({ row, name, onChange }) => {
  const classes = useStyles();
  const { isEditMode } = row;
  var value = [];
  var showText = false;
  if (name === "day" || name === "hours") {
    showText = true;
  }
  if (name === "day") {
    value.push(<span key={row.id} style={{ textTransform: "capitalize" }}>{row[name]}</span>)
  } else if (name === "hours") {
    var hours_diff = '';
    if (row['start_time'] && row['end_time']) {
      var startTime = moment(row['start_time'], "hh:mm");
      var endTime = moment(row['end_time'], "hh:mm");
      var breakTime = row['break'];
      var duration = moment.duration(endTime.diff(startTime));

      if(breakTime > 0){
        duration = duration.asMinutes() - breakTime;
      }else{
        duration = duration.asMinutes();
      }
      
      var hours = parseInt(duration / 60);
      var minutes = duration % 60;
      hours_diff = `${hours}:${minutes}`;
    }

    value.push(<span key={row.id}>{hours_diff ? moment(hours_diff, "hh:mm").format("hh:mm") : ''}</span>)
  } else if (name === "rdo_accrual" || name === "ordinary_hours") {
    value.push(<span key={row.id}>{row[name]}</span>)
  }

  return (
    <TableCell align="left" className={classes.tableCell}>
      {showText ? value :
        isEditMode ? (
          <Input
            type="number"
            value={row[name] ?? ''}
            name={name}
            onChange={e => onChange(e, row, name)}
            className={classes.input}
          />
        ) : (
          row[name]
        )}
    </TableCell>
  );
};

export const createData = (id, day, start_time, end_time, break_time, hours, ordinary_hours, rdo_accrual) => ({
id: id,
day: day,
start_time: start_time,
end_time: end_time,
break: break_time,
hours: hours,
ordinary_hours: ordinary_hours,
rdo_accrual: rdo_accrual,
isEditMode: false
});


export const useStyles = makeStyles(theme => ({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto"
    },
    table: {
      minWidth: 650,
      '& .MuiTableCell-root': {
        padding: "0px"
      }
    },
    selectTableCell: {
      width: 100
    },
    tableCell: {
      width: 130,
      height: 20,
      fontSize: "14px"
    },
    input: {
      width: 130,
      height: 20,
      fontSize: "14px"
    },
    timepicker: {
      '& .MuiInputBase-root.MuiInput-root': {
        height: 20
      },
      '& .MuiInputBase-input': {
        fontSize: "12px"
      },
    }
  }));
