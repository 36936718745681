import React, { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import {
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import {
  CancelNewTimesheetButton,
  AddNewTimesheetButton,
} from './style.js';
import SelectEmployee from './SelectEmployee';
import { useStylesAddSession } from './style.js';
import directus from '../../../services/directus';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import useStateRef from "react-usestateref";
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../../views/Components/ErrorMessage';
import linq from "linq";
import EventEmitter from 'src/utils/EventEmitter';
import { getConfiguration } from 'src/utils/sessions';
import moment from 'moment';
import { enableRipple } from '@syncfusion/ej2-base';
import { Skeleton, Grid } from '@mui/material';
import { DirectusEmployee } from 'src/views/Components/LocalstorageData.js';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const directusEmployeeData = DirectusEmployee();

let startApprovals;
let endApprovals;
let filterSessionLeave = 0;
let filterTimesheets = [];
let loop = 0;

const AddEmployeeTimesheet = () => {
  const classes = useStylesAddSession();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [items, setItems] = React.useState({ employees: null, timesheets: null, sessions: null });
  const [employeesFilterData, setEmployeesFilterData] = React.useState(null);
  const [initialized, setInitialized] = React.useState(false);
  const [employeesFilterChosen, setEmployeesFilterChosen] = React.useState(null);
  const [status, setStatus, statusRef] = useStateRef('all');
  const [statusView, setStatusView, statusViewRef] = useStateRef(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startDate, setStartDate, startDateRef] = useStateRef('');
  const [endDate, setEndDate, endDateRef] = useStateRef('');
  const [openError, setOpenError] = React.useState(false);
  const [addTimesheetLoading, setAddTimesheetLoading] = React.useState(false);
  const [moveLoading, setMoveLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [searchApproval, setSearchApprovals, SearchApprovalRef] = useStateRef('');
  const [errorText, setErrorText] = React.useState(null);
  const [configuration, setConfiguration] = useState(null);


  /* eslint-enable */
  enableRipple(true);

  var react_app_url = process.env.REACT_APP_URL;
  if (window.location.hostname.search('interax') !== -1) {
    react_app_url = process.env.REACT_APP_URL_INTERAX;
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      firstLoad();
      loadConfiguration();
    }

    const changeDate = (eventData) => {
      if (eventData.text === 'changeDate') {
        firstLoad();
      }
    }
    const listenChangeDate = EventEmitter.addListener('changeDate', changeDate);

    return () => {
      listenChangeDate.remove();
      setInitialized(false);
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps*/


  const loadConfiguration = async () => {
    try {
      var configuration_data = await directus.getItems('config_ts');
      setConfiguration(configuration_data.data[0]);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e.code), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  };

  const firstLoad = async () => {

    if (window.localStorage.getItem('approvals_date')) {
      startApprovals = JSON.parse(window.localStorage.getItem('approvals_date')).start;
      endApprovals = JSON.parse(window.localStorage.getItem('approvals_date')).end;
    }
    else {
      var ConfigurationResult = await getConfiguration();
      let config_day = moment(moment().day(ConfigurationResult.first_day_of_week)._d);

      if (moment(moment().add(-2, 'd').format('YYYY-MM-DD')).isBefore(moment(config_day).format('YYYY-MM-DD'))) {
        startApprovals = (moment(config_day).add(-7, 'd').format('YYYY-MM-DD 00:00:00'));
        endApprovals = (moment(startApprovals).add(6, 'd').format('YYYY-MM-DD 23:59:59'));
      }
      else {
        startApprovals = (moment(config_day).format('YYYY-MM-DD 00:00:00'));
        endApprovals = (moment(startApprovals).add(6, 'd').format('YYYY-MM-DD 23:59:59'));
      }
    }

    setStartDate(startApprovals ? startApprovals : moment().add(-2, 'd').format('YYYY-MM-DD 00:00:00'));
    setEndDate(endApprovals ? endApprovals : moment().add(-2, 'd').format('YYYY-MM-DD 23:59:59'));

    loadTimeSheets('null', statusRef.current);

  }

  const loadTimeSheets = async (employeeData, status) => {
    setMoveLoading(true);
    let employeesId = [];
    let filterData;
    let employeeArray = [];
    let loopCountEmployee = 0;
    let employeeCount = 0;

    if (employeeData !== 'null') {
      if (employeeData.length > 30) {
        employeeData.map((data, index) => {

          if (data) {
            if (index !== 0 && index % 30 === 0) {
              employeeArray[loopCountEmployee] = employeesId;
              employeesId = [];
              loopCountEmployee = loopCountEmployee + 1;
              employeeCount = 0
            }
            employeesId[employeeCount] = data.id;
            employeeCount = employeeCount + 1;

            if (index + 1 === employeeData.length) {
              employeeArray[loopCountEmployee] = employeesId;
            }
          }
        })
      }
      else {
        employeeData.map((data, index) => {
          if (data) {
            employeesId[index] = data.id;
          }
        })
      }
    }
    else {
      if (SearchApprovalRef.current) {
        filterData = {
          'employee.status': { in: ['published', 'deleted'] },
          'employee.user.first_name': { like: SearchApprovalRef.current },
          'employee.user.last_name': { 'logical': 'or', like: SearchApprovalRef.current },
          'employee.code': { 'logical': 'or', like: SearchApprovalRef.current },
        };
      }

      else {
        filterData = {
          'employee.status': { in: ['published', 'deleted'] },
        };
      }
    }

    if (status === 'all') {
      filterData = {
        ...filterData,
      }
    }
    else {
      filterData = {
        ...filterData,
        status: { eq: status }
      }
    }


    filterData = {
      ...filterData,
      start_time: { gte: startDateRef.current },
      end_time: { lte: endDateRef.current },
    }

    let TimesheetsResult = [];
    let countTimesheetArray = 0;
    if (employeeData.length > 30) {
      for (const employee of employeeArray) {
        filterData = {
          ...filterData,
          employee: { in: employee },
        }
        try {

          let fetchTimesheetResult = await directus.api.get('/custom/approvals/timesheets', {
            fields: 'id,end_time,start_time,status,total_minutes,employee.user.id,employee.user.first_name,employee.user.last_name,employee.id,employee.code,employee.status,employee.type,employee.leave_approver,employee.mobile_number,employee.parent_leave',
            filter: filterData,
            limit: -1,
            show_timesheet_review: true,
            timesheet_review_filter:
            {
              "read_by.employees_id": { in: [directusEmployeeData.id]}
            },
          });

          TimesheetsResult = TimesheetsResult.concat(fetchTimesheetResult.data);

          countTimesheetArray = countTimesheetArray + 1;
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
    }
    else {
      filterData = {
        ...filterData,
        employee: { in: employeesId },
      }

      try {
        let fetchTimesheetResult = await directus.api.get('/custom/approvals/timesheets', {
          fields: 'id,end_time,start_time,status,total_minutes,employee.user.id,employee.user.first_name,employee.user.last_name,employee.id,employee.code,employee.status,employee.type,employee.leave_approver,employee.mobile_number,employee.parent_leave',
          filter: filterData,
          limit: -1,
          show_timesheet_review: true,
          timesheet_review_filter:
          {
            "read_by.employees_id": { in: [directusEmployeeData.id] }
          },
        });

        TimesheetsResult = TimesheetsResult.concat(fetchTimesheetResult.data);

      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    let timesheetId = [];

    TimesheetsResult.map((data) => {
      timesheetId = [...timesheetId, data.id];
    })

    try {
      var TimesheetReview = await directus.api.post('/custom/mobile/timesheets/batch-data', {
        "timesheet_review": {
          fields: '*,employee.user.first_name,employee.user.last_name,read_by.employees_id.id',
          filter: {
            'timesheet.id': { in: timesheetId }
          },
          sort: "created_on"
        }
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    TimesheetsResult.map((data, index) => {
      let result = 0
      result = linq.from(TimesheetReview.data.timesheet_review)
        .where(x => x.timesheet.id === data.id).count();

      TimesheetsResult[index] = { ...data, totalCountTimesheet: result, select: false };
    })

    if (TimesheetsResult.length > 0) {
      if (statusViewRef.current === 2) {
        filterSessionLeave = 0;
        filterTimesheets = [];
        loop = 0;
        filterLeaveStatus(TimesheetsResult)
      }
      else {
        TimesheetFinishing(TimesheetsResult);
      }
    }
    else {
      setItems(prevItems => ({
        ...prevItems,
        timesheets: TimesheetsResult,
      }));

      setMoveLoading(false);
      loadEmployeeFilter();
    }

  };

  const filterLeaveStatus = async (TimesheetsResult) => {
    try {
      var SessionResult = await directus.getItems('sessions', {
        fields: '*.*',
        filter: {
          'timesheet.id': { eq: TimesheetsResult[filterSessionLeave].id },
          'session_type.leave': { nempty: true }
        },
        limit: -1
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    if (SessionResult.data.length > 0) {
      filterTimesheets[loop] = TimesheetsResult[filterSessionLeave];
      loop = loop + 1;
    }

    if (filterSessionLeave + 1 === TimesheetsResult.length) {
      TimesheetFinishing(filterTimesheets);
    }
    else {
      filterSessionLeave = filterSessionLeave + 1;
      filterLeaveStatus(TimesheetsResult);
    }
  }

  const TimesheetFinishing = async (TimesheetsResult) => {
    let filterdata;
    let filterTimesheet;

    if (SearchApprovalRef.current) {
      filterdata = linq.from(TimesheetsResult)
        .where(x => x.start_time === startDateRef.current && x.end_time === endDateRef.current && x.status !== 'deleted').toArray();
      filterTimesheet = linq.from(filterdata)
        .orderBy(x => x.employee.code).toArray();
    }
    else {
      filterTimesheet = linq.from(TimesheetsResult)
        .orderBy(x => x.employee.code).toArray();
    }

    filterTimesheet = linq.from(filterTimesheet)
      .orderByDescending(x => x.status === 'awaiting_approval')
      .thenByDescending(x => x.status === 'pending')
      .thenByDescending(x => x.status === 'approved')
      .thenByDescending(x => x.status === 'rejected').toArray();

    setItems(prevItems => ({
      ...prevItems,
      timesheets: filterTimesheet
    }));

    setMoveLoading(false);
    loadEmployeeFilter();
  }

  const loadEmployeeFilter = async () => {
    let ExcludeEmployee;
    try {
      var TimesheetsResult = await directus.api.get('/custom/approvals/timesheets', {
        fields: 'id,employee.id',
        filter: {
          start_time: { gte: startDateRef.current },
          end_time: { lte: endDateRef.current },
        },
        limit: -1,
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    TimesheetsResult.data.map((data, index) => {
      if (index === 0) {
        if (data.employee !== null) {
          ExcludeEmployee = data.employee.id;
        }
      }
      else {
        if (data.employee !== null) {
          ExcludeEmployee = ExcludeEmployee + "," + data.employee.id;
        }
      }
    })

    try {
      var EmployeeResult = await directus.getItems('employees', {
        fields: 'user.first_name,user.last_name,user.email,id,code,status',
        filter: {
          id: { nin: ExcludeEmployee },
          status: { eq: 'published' },
        },
        sort: 'code',
        limit: -1
      });
      let empResult = [];
      empResult = linq.from(EmployeeResult.data)
        .where(x => x.user !== null).toArray();

      setEmployeesFilterData(empResult);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

  }

  const addTimesheet = async () => {
    let addTotalEmployee = [];
    setLoading(true);

    employeesFilterChosen.map((data) => {
      addTotalEmployee.push({
        employee: data.id,
        start_time: startDateRef.current,
        end_time: endDateRef.current,
        total_minutes: 0,
        status: 'pending'
      })
    })

    let EmployeeId = [];
    let TimesheetId = [];

    employeesFilterChosen.map((data) => {
      EmployeeId.push(data.id);
    })

    try {
      var TimesheetsCheck = await directus.getItems('timesheets', {
        fields: 'id,employee.id,start_time,end_time',
        filter: {
          start_time: { gte: startDateRef.current },
          end_time: { lte: endDateRef.current },
          total_minutes: 0,
          'employee.id': { eq: EmployeeId }
        },
        limit: -1,
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    TimesheetsCheck.data.map((data) => {
      TimesheetId.push(data.id);
    })

    try {
      if (TimesheetsCheck.data.length === 0) {
        await directus.createItems('timesheets', addTotalEmployee);
        setErrorText(null);
      }
      else {
        await directus.deleteItems('timesheets', TimesheetId);
        await directus.createItems('timesheets', addTotalEmployee);
        setErrorText(null);
      }
    }
    catch (e) {
      if (TimesheetId.length === 0) {
        setOpenError(true);
        setErrorText('This employee already has a timesheet created for this week that you do not have access to.\n Please check with you administrator how to get access.');
      }
    }

    loadTimeSheets('null', statusRef.current);
    setLoading(false);
    handleCloseDialog();
    EventEmitter.emit('reloadpage', { text: 'reload' });
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  return (
    <>
      <IconButton id="add_order_menu"
        className={classes.add}
        onClick={() => handleOpenDialog()}
      >
        <AddIcon className={classes.iconAdd} />
      </IconButton>

      <Dialog
        id="add_new_timesheet_dialog"
        aria-labelledby="add_new_timesheet_dialog"
        open={openDialog}
      >
        <DialogTitle>Add New Timesheets</DialogTitle>
        <DialogContent>
          <SelectEmployee
            employeesFilterData={employeesFilterData}
            setEmployeesFilterChosen={(value) => setEmployeesFilterChosen(value)}
            icon={icon}
            checkedIcon={checkedIcon}
          />
        </DialogContent>
        {!employeesFilterData ?
          <DialogActions>
            <Grid container spacing={0} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Skeleton animation="wave" variant="rounded" height={30} width={120} style={{ borderRadius:'8px', marginRight: '15px' }}/>
              <Skeleton animation="wave" variant="rounded" height={30} width={150} style={{ borderRadius:'8px' }}/>
            </Grid>
          </DialogActions>
          :
          <DialogActions>
            {loading ?
              <CircularProgress style={{ width: '25px', height: '25px', marginRight: '16px' }} className={classes.circular} />
              :
              <>
                <CancelNewTimesheetButton
                  id="cancel_btn"
                  size="small"
                  variant="contained"
                  onClick={handleCloseDialog}
                  display='none'
                >
                  <Typography variant="h6">Cancel</Typography>
                </CancelNewTimesheetButton>
                <AddNewTimesheetButton
                  id="add_btn"
                  variant="contained"
                  onClick={() => addTimesheet(employeesFilterChosen)}
                >
                  <Typography variant="h6">Add Selected</Typography>
                </AddNewTimesheetButton>
              </>
            }
          </DialogActions>
        }
      </Dialog>
    </>
  );
}



export default AddEmployeeTimesheet;