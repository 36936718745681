import directus from 'src/services/directus';
import { DirectusEmployee } from 'src/views/Components/LocalstorageData.js';

/**
 * Get current directus user
 * @param {boolean} forceReload
 */

const directusEmployeeData = DirectusEmployee();

export async function getUser(forceReload = false) {
  try {
    // var user = JSON.parse(window.localStorage.getItem('directus_data'));
    if (forceReload) {
      var result = await directus.getItem('directus_users', directusEmployeeData.user.id, { fields: '*.*' });
      if (!result.error) {
        window.localStorage.setItem('directus_user', JSON.stringify(result.data));
        return result.data;
      }
      else
      {
        window.localStorage.clear();
        window.location.reload();
      }
    }
  } catch (err) {}

  return JSON.parse(window.localStorage.getItem('directus_user'));
}

/**
 * Get current employee
 * @param {boolean} forceReload
 */
export async function getEmployee(forceReload = false) {
  try {
    if (forceReload) {
      var result = await directus.getItem('employees', directusEmployeeData.id, { fields: '*.*' });
      if (!result.error) {
        window.localStorage.setItem('directus_employee', JSON.stringify(result.data));
        return result.data;
      }
      else
      {
        window.localStorage.clear();
        window.location.reload();
      }
    }
  } catch (err) {}

  return directusEmployeeData;
}

export function isAuthenticated() {
  return window.localStorage.getItem('directus_users') && directusEmployeeData;
}

export async function redirectLogin(){
  if(directusEmployeeData !== null)
  {
    try{
      await directus.getMe();
    }
    catch(e)
    {
      window.localStorage.clear();
      window.location.reload();
    }
  }
}

export async function refreshToken() {

  if(JSON.parse(window.localStorage.getItem('directus_data')))
  {
    const savedData = JSON.parse(window.localStorage.getItem('directus_data'));
    var result;
    
    try{
      result = await directus.refresh(savedData.token);
    } 
    catch (err) {}

    window.localStorage.setItem('directus_data', JSON.stringify({...savedData, token: result.data.token}));

  }
}

export function loginDirect() {
  window.localStorage.clear();
  window.location.reload();
}

export async function getConfiguration(forceReload = false) {
  if(JSON.parse(window.localStorage.getItem('directus_data')))
  {
    try {
      if (forceReload) {
        var result = await directus.getItems('configuration');
        if (!result.error) {
          window.localStorage.setItem('configuration', JSON.stringify(result.data[0]));
          return result.data;
        }
        else
        {
          window.localStorage.clear();
          window.location.reload();
        }
      }
    } catch (err) {console.log(err);}

    return JSON.parse(window.localStorage.getItem('configuration'));
  }
}

export async function getConfigurationTS(forceReload = false) {
  if(JSON.parse(window.localStorage.getItem('directus_data')))
  {
    try {
      if (forceReload) {
        var result = await directus.getItems('config_ts');
        if (!result.error) {
          window.localStorage.setItem('config_ts', JSON.stringify(result.data[0]));
          return result.data;
        }
        else
        {
          window.localStorage.clear();
          window.location.reload();
        }
      }
    } catch (err) {console.log(err);}

    return JSON.parse(window.localStorage.getItem('config_ts'));
  }
}
