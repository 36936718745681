import React from 'react';
import { 
  Container, 
  AppBar,
  Tabs,
  Tab,
  Box 
} from '@material-ui/core';
import Page from 'src/components/Page';
import PropTypes from 'prop-types';
import Employees from './Employees';
import Customers from './Customers';
import PublicHolidays from './PublicHolidays';
import Activities from './Activities';
import Awards from './Awards';
import SessionOption from './SessionOption';
import Integration from './Integration';
import ConfigurationSettings from './Configuration';
import DeletedEmployees from './DeletedEmployees';
import EventEmitter from 'src/utils/EventEmitter';
import { useStyles } from './style.js';
import { DirectusEmployee } from 'src/views/Components/LocalstorageData.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={30}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const directusEmployeeData = DirectusEmployee();

const Settings = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  let enableAward = JSON.parse(window.localStorage.getItem('config_ts')).enable_awards;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    changeTab(newValue);
  };

  const changeTab = (data) => {
    EventEmitter.emit('changeTab', {
      text: data
    })
  }


  return (
    <Page className={classes.root} title="Settings">
      <Container maxWidth={false} style={{padding:'0px 20px'}}>
        <div className="control-pane">
          <div className="control-section">
            <AppBar position="static" className={classes.Appbar} >
              <Tabs value={value} onChange={handleChange} aria-label="settings tab" className={classes.tabs} >
                <Tab className={classes.first} label="Employees" {...a11yProps(0)} /> 
                <Tab className={classes.middle} label="Customers"  {...a11yProps(1)} /> 
                <Tab className={classes.middle} label="Public Holidays"  {...a11yProps(2)} />
                <Tab className={classes.middle} label="Activities"  {...a11yProps(3)} />
                <Tab className={classes.middle} label="Awards"  {...a11yProps(4)} style={enableAward?{}:{display:'none'}} />
                <Tab className={classes.middle} label="Session Option"  {...a11yProps(4)} style={enableAward?{}:{display:'none'}} />
                <Tab className={classes.middle} label="Integration"  {...a11yProps(5)} />
                <Tab className={classes.middle} label="Configuration"  {...a11yProps(6)} />
                {/* <Tab className={classes.last} label="Show Deleted"  {...a11yProps(7)} /> */}
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} className={classes.tab}>
              <Employees directus_employee={directusEmployeeData.id} />
            </TabPanel>
            <TabPanel id="customers_panel" value={value} index={1} className={classes.tab}>
              <Customers/>
            </TabPanel>
            <TabPanel id="holiday_panel" value={value} index={2} className={classes.tab}>
              <PublicHolidays/>
            </TabPanel>
            <TabPanel id="activities_panel" value={value} index={3} className={classes.tab}>
              <Activities/>
            </TabPanel>
            <TabPanel id="awards_panel" value={value} index={4} className={classes.tab} >
              <Awards/>
            </TabPanel>
            <TabPanel id="awards_panel" value={value} index={5} className={classes.tab} >
              <SessionOption/>
            </TabPanel>
            <TabPanel id="integration_panel" value={value} index={6} className={classes.tab}>
              <Integration />
            </TabPanel>
            <TabPanel id="configuration_panel" value={value} index={7} className={classes.tab}>
              <ConfigurationSettings/>
            </TabPanel>
            {/* <TabPanel id="deleted_employee_panel" value={value} index={8} className={classes.tab}>
              <DeletedEmployees/>
            </TabPanel> */}
          </div>
        </div>
      </Container>
    </Page>
  );
};

export default Settings;
