import React from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent,
} from '@material-ui/core';
import { TextFieldCustom, AutoCompleteCustom } from './style.js';
import HailIcon from '@mui/icons-material/Hail';
import { SubmitButton } from '../Settings/style.js';
import { LeaveSupervisorChip, useStylesEmployees, CustomGridApproval, CustomSwitch } from './style.js';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ConfigurationViewModel } from "../../ViewModel/Settings/ConfigurationViewModel";
import Skeleton from '@mui/material/Skeleton';
import FormGroup from '@mui/material/FormGroup';

export default function Configuration() {
  const [
    //states
    {
      configurationRef, maxBreakTimeRef, timezoneRef, timezoneFilterChosenRef,
      emailNotificationStatusRef, checkedRef, loading
    },
    //setStates
    {
      setTimezoneFilterChosen,
    },
    //function
    {
      handleChangeInput, switchHandleChange, toggleChecked, SaveConfiguration
    }
  ] = ConfigurationViewModel();

  const classes = useStylesEmployees();

  return (
    <div>
      {configurationRef.current?
        <Card elevation={1} className={classes.card}>
          <CardContent>
            <Grid
              container 
              spacing={3}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={2} style={{textAlign:'right'}}>
                <Typography variant="h5">Max Break Time:</Typography> 
              </Grid>
              <Grid item xs={10}>
                <TextFieldCustom
                  size='small'
                  id="max_break_time"
                  label="Max Break Time"
                  variant="outlined"
                  margin="normal"
                  name="max_break_time"
                  type="number"
                  value={maxBreakTimeRef.current ? maxBreakTimeRef.current:''}
                  onChange={handleChangeInput}
                />      
              </Grid>
              <Grid item xs={2} style={{textAlign:'right'}}>
                <Typography variant="h5"> Default Timezone:</Typography> 
              </Grid>
              <Grid item xs={3}>
                <AutoCompleteCustom
                  aria-label='Select Timezone'
                  id="combo-box-timezone"
                  options={timezoneRef.current || []}
                  getOptionLabel={(option) => option.value || ""}
                  getOptionSelected={(option, value) => option.value === value.value}
                  renderInput={(params) => <TextFieldCustom {...params} label="Select Timezone" size='small' variant="outlined" />}
                  onChange={(event, newValue) => {
                    newValue === null ?
                      setTimezoneFilterChosen({ id: 0, name: '', value: '' })
                      :
                      setTimezoneFilterChosen({ id: newValue.id, name: newValue.name, value: newValue.value })
                  }}
                  value={timezoneFilterChosenRef.current ? timezoneFilterChosenRef.current: ''}
                />   
              </Grid>
              <Grid item xs={7}></Grid>
              <Grid item xs={2} style={{textAlign:'right'}}>
                <Typography variant="h5">Enable Email Notifications:</Typography> 
              </Grid>
              <Grid item xs={10}>
                <div style={{ display: 'flex' }}>
                  <FormGroup>
                    <FormControlLabel style={{ marginRight: '5px' }} control={<CustomSwitch aria-label='Enable Email Notifications' checked={emailNotificationStatusRef.current} onChange={switchHandleChange} />}/>
                  </FormGroup>
                </div>
              </Grid>
              <Grid item xs={2} style={{textAlign:'right'}}>
                <Typography variant="h5">Leave Supervisor :</Typography>
              </Grid>
              <Grid item xs={10}>
                <div style={{ display: 'flex', minHeight: '40px', alignItems: 'center' }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <CustomSwitch aria-label='Leave Supervisor' checked={checkedRef.current} onChange={toggleChecked} />
                      }
                      label = {
                        <div>
                          {checkedRef.current? 
                            <LeaveSupervisorChip 
                              style={{ marginLeft: '5px'}}
                              variant="outlined" 
                              size="small"
                              icon={<HailIcon sx={{ color: '#FFFFFF' }} />}
                            />
                          :''}
                        </div>
                      }
                    />
                  </FormGroup>
                </div>
              </Grid>
              <Grid item xs={2} style={{textAlign:'right'}}></Grid>
              <Grid item xs={10}>
                {configurationRef.current.config_settings?
                  loading?
                    <Skeleton animation="wave" width={120} variant="rounded" height={30} style={{borderRadius:'8px'}}/>
                  :
                    <SubmitButton
                      id="save_btn"
                      size="small"
                      variant="contained"
                      onClick={() => SaveConfiguration()}
                    >
                      Save
                    </SubmitButton>
                :''} 
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      :
      <>
        <div style={{ marginTop: '40px', marginLeft: '25px'}}>
          <Grid container spacing={3} style={{ marginBottom: '35px'}}>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
              <Skeleton animation="wave" variant="rounded" width={150} height={34} style={{borderRadius:'8px', marginLeft: 'auto'}}/>
            </CustomGridApproval>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
              <Skeleton animation="wave" variant="rounded" height={34} style={{borderRadius:'8px'}}/>
            </CustomGridApproval>
          </Grid>
          <Grid container spacing={4} style={{ marginBottom: '35px'}}>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
              <Skeleton animation="wave" variant="rounded" width={165} height={34} style={{borderRadius:'8px', marginLeft: 'auto'}}/>
            </CustomGridApproval>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={3}>
              <Skeleton animation="wave" variant="rounded" height={34} style={{borderRadius:'8px'}}/>
            </CustomGridApproval>
          </Grid>
          <Grid container spacing={4} style={{ marginBottom: '35px'}}>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
              <Skeleton animation="wave" variant="rounded" width={210} height={34} style={{borderRadius:'8px', marginLeft: 'auto'}}/>
            </CustomGridApproval>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
              <Skeleton animation="wave" variant="rounded" height={34} style={{borderRadius:'8px'}}/>
            </CustomGridApproval>
          </Grid>
          <Grid container spacing={4} style={{ marginBottom: '35px'}}>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
              <Skeleton animation="wave" variant="rounded" width={155} height={34} style={{borderRadius:'8px', marginLeft: 'auto'}}/>
            </CustomGridApproval>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1}>
              <Skeleton animation="wave" variant="rounded" width={100} height={34} style={{borderRadius:'8px'}}/>
            </CustomGridApproval>
          </Grid>
          <Grid container spacing={4} style={{ marginTop: '10px'}}>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
            </CustomGridApproval>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1}>
              <Skeleton animation="wave" variant="rounded" height={34} style={{borderRadius:'8px'}}/>
            </CustomGridApproval>
          </Grid>
        </div>
      </>
      }
    </div>
  );
}