import React, { useState, useEffect } from 'react';
import useStateRef from "react-usestateref";
import { Box, List, makeStyles, Typography, } from '@material-ui/core';
import directus from '../../../services/directus';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../../views/Components/ErrorMessage';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import EventEmitter from 'src/utils/EventEmitter';
import Switch from '@mui/material/Switch';
import {
  AddQuickFilter,
  CancelQuickFilter,
  ApplyButton,
  FilterButton,
  SaveFilterButton,
  EditQuickFilter,
  QuickFiltersChip,
  QuickFiltersFormControlLabel,
} from '../style.js';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  TextFieldCustom,
  TextFieldFilterTimesheetCustom,
  CheckboxCustom,
  AutocompleteFilterTimesheetCustom,
} from './style.js';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: '300px',
    top: '80px',
    backgroundColor: 'white',
    borderRight: '0px'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  List: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '4px'
  },
  box: {
    padding: '0 0 0 26px',
  },
  chipcustom: {
    height: '20px',
    width: 'auto',
    marginTop: '3px',
    '& .MuiChip-label': {
      paddingLeft: '6px',
      paddingRight: '6px',
      paddingTop: '1px'
    }
  },
  circularSkeletonCustom: {
    height: '20px',
    width: '20px',
    marginTop: '4px',
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '1px'
  },
  cardCustom: {
    margin: '0px 10px 10px 16px',
    borderRadius: '8px !important',
    boxShadow: 'none !important',
    '& .MuiCardContent-root': {
      padding: '6px 0px 0px 0px'
    },
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '8px !important'
    }
  },
  tabstyle: {
    fontSize: '15px',
    fontWeight: '700',
    lineHeight: '20.49px'
  },
  fontstyle1: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16.39px'
  },
  fontstyle2: {
    fontSize: '10px',
    fontWeight: '700',
    lineHeight: '13.66px'
  },
  fontstyle3: {
    fontSize: '9px',
    fontWeight: '400',
    lineHeight: '12.99px'
  },
  fontstyle4: {
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '13.66px',
    textAlign: 'right'
  },
}));

let filters_approvals=JSON.parse(window.localStorage.getItem('filters_approvals'));

const Filter = () => {
  /* eslint-disable no-unused-vars*/
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [initialized, setInitialized] = React.useState(false);
  const [timesheetStatusType, setTimesheetStatusType] = React.useState([]);
  const [employmentType, setEmploymentType] = React.useState([]);
  const [employeeRole, setEmployeeRole] = React.useState([]);

  const [checked, setChecked, checkedRef] = useStateRef([false, false, false]);
  const [timesheetStatus, setTimesheetStatus, timesheetStatusRef] = useStateRef([]);
  const [type, setType, typeRef] = useStateRef([]);
  const [role, setRole, roleRef] = useStateRef([]);


  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      statusSummaryTypeLoad();
      employmentTypeLoad();
      employeeRoleLoad();

      // if(filters_approvals){
      //   setChecked([filters_approvals.checkedStatus]);
      //   setTimesheetStatus(filters_approvals.timesheetStatus);
      //   setType(filters_approvals.type);
      //   setRole(filters_approvals.role);
      // }
    }

    return () => {
      setInitialized(false);
    }
  }, []);

  const FiltersTimesheet = () => {
    EventEmitter.emit('FiltersTimesheet', {
      checkedStatus: checkedRef.current,
      timesheetStatus: timesheetStatusRef.current,
      type: typeRef.current,
      role: roleRef.current,
    })

    // window.localStorage.setItem('filters_approvals', 
    // JSON.stringify({ 
    //   checkedStatus: checkedRef.current,
    //   timesheetStatus: timesheetStatusRef.current,
    //   type: typeRef.current,
    //   role: roleRef.current,
    // }));
  }

  const resetFilters = () => {
    setChecked([false, false, false]);
    setTimesheetStatus([]);
    setType([]);
    setRole([]);
  };

  const handleChangeOnlyLeavesEntries = (event) => {
    setChecked([event.target.checked, checkedRef.current[1], checkedRef.current[2]]);
  };

  const handleChangeUnreadMessage = (event) => {
    setChecked([checkedRef.current[0], event.target.checked, checkedRef.current[2]]);
  };

  function handleChangeDeletedStatus(event) {
    setChecked([checkedRef.current[0], checkedRef.current[1], event.target.checked]);
  }

  function handleChangeTimesheetStatus(value) {
    setTimesheetStatus(value);
  }

  function handleChangeEmploymentType(value) {
    setType(value);
  }

  function handleChangeEmployeeRole(value) {
    setRole(value);
  }

  const statusSummaryTypeLoad = async () => {
    try {
      var timesheet_data = await directus.getField("timesheets", "status");
      const timesheet_status = Object.entries(timesheet_data.data.options.status_mapping);
      var timesheet_list = [];
      if (timesheet_status.length > 0) {
        timesheet_status.map((value) => {
          if (value[1].value !== "deleted") {
            var name = value[1].name;
            if (value[1].value === "pending") {
              name = "Not Yet Submitted";
            } else if (value[1].value === "awaiting_approval") {
              name = "Waiting Approval";
            } else if (value[1].value === "sent") {
              name = "Sent to Payroll";
            }
            timesheet_list.push({
              value: value[0],
              name: name,
            })
          }
        })

        setTimesheetStatusType(timesheet_list);
      }
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  }

  const employmentTypeLoad = async () => {

    let employmentTypeResult = [];
    var emp_data = await directus.getField("employees", "type");
    const emp_list = Object.entries(emp_data.data.options.choices);
    emp_list.map((data, index) => {
      employmentTypeResult[index] = { id: data[0], name: data[1], value: data[0] };
    })
    setEmploymentType(employmentTypeResult);
  }

  const employeeRoleLoad = async () => {
    let employeeRoleResult = [];
    var role_data = await directus.getRoles();

    role_data.data.map((data, index) => {
      if (data.name === 'Admin' || data.name === 'Employee' || data.name === 'Supervisor') {
        employeeRoleResult[index] = { id: data.id, name: data.name };
      }
    });
    setEmployeeRole(employeeRoleResult);
  }

  return (
    <Box className={classes.List}>
      <List>
        <ListItem>
          <ListItemText className={classes.tabstyle}> <b>Filters</b></ListItemText>
        </ListItem>
        <Divider style={{ margin: '0px 16px 14px 16px' }} />
        <>
          <Card className={classes.cardCustom}>
            <CardContent>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={0.5}
              >
                <Grid item xs={10}><Typography className={classes.fontstyle1}>Only Timesheets with Leave</Typography></Grid>
                <Grid item xs={2}>
                  <Switch name="only_leaves_entries" checked={checked[0]} size="small" onChange={handleChangeOnlyLeavesEntries} />
                </Grid>

                <Grid item xs={10}><Typography className={classes.fontstyle1}>Unread Messages</Typography></Grid>
                <Grid item xs={2}>
                  <Switch name="unread_messages" checked={checked[1]} size="small" onChange={handleChangeUnreadMessage} />
                </Grid>

                <Grid item xs={10}><Typography className={classes.fontstyle1}>Include Deleted Employees</Typography></Grid>
                <Grid item xs={2}>
                  <Switch name="deleted_status" checked={checked[2]} size="small" onChange={handleChangeDeletedStatus} />
                </Grid>

                <Grid item xs={12}><Typography className={classes.fontstyle1}>Timesheet Status</Typography></Grid>
                <Grid item xs={12}>
                  <AutocompleteFilterTimesheetCustom
                    multiple
                    id="filterTimesheet"
                    options={timesheetStatusType}
                    disableCloseOnSelect
                    getOptionSelected={(option, value) => option.value === value.value}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={(event, newValue) => {
                      handleChangeTimesheetStatus(newValue);
                    }}
                    value={timesheetStatus}
                    renderOption={(option, { selected }) => (
                      <Typography className={classes.fontstyle3}>
                        <CheckboxCustom
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={selected}
                        />
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextFieldFilterTimesheetCustom {...params} />
                    )}
                  />
                </Grid>

                <Grid item xs={12}><Typography className={classes.fontstyle1}>Employee Type</Typography></Grid>
                <Grid item xs={12}>
                  <AutocompleteFilterTimesheetCustom
                    disablePortal
                    id="combo-box-demo"
                    options={employmentType}
                    getOptionSelected={(option, value) => option.value === value.value}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={(event, newValue) => {
                      handleChangeEmploymentType(newValue);
                    }}
                    value={typeRef.current}
                    renderInput={(params) => <TextFieldFilterTimesheetCustom {...params} />}
                  />
                </Grid>

                <Grid item xs={12}><Typography className={classes.fontstyle1}>Employee Role</Typography></Grid>
                <Grid item xs={12}>
                  <AutocompleteFilterTimesheetCustom
                    disablePortal
                    id="combo-box-demo"
                    options={employeeRole}
                    getOptionSelected={(option, value) => option.value === value.value}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={(event, newValue) => {
                      handleChangeEmployeeRole(newValue);
                    }}
                    value={roleRef.current}
                    renderInput={(params) => <TextFieldFilterTimesheetCustom {...params} />}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <Grid container
                alignItems="center"
              >
                <Grid item xs={12}>
                  <ApplyButton variant="contained" fullWidth onClick={() => FiltersTimesheet()}>
                    <Typography className={classes.fontstyle1}>Apply Filter</Typography>
                  </ApplyButton>
                </Grid>
                <Grid item xs={12}>
                  <FilterButton variant="contained" fullWidth onClick={() => resetFilters()}>
                    <Typography className={classes.fontstyle1}>Reset Filter</Typography>
                  </FilterButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </>
      </List>
    </Box>
  );
};

export default Filter;
