import React from 'react';
import { 
  List,
  ListItem,
  CircularProgress,
  Button,
  Chip,
  Typography,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  TextField,
  MenuItem
} from '@material-ui/core';
import { 
  useStylesEmployees,
  AddButton, 
  SubmitButton, 
  CancelButton, 
  pageOptions, 
  CustomGridApproval,
  CustomAccordion,
  CustomAccordionSummary,
  GridCustom,
  TextFieldCustom,
  AutoCompleteCustom,
  TextFieldCustom2
} from './style.js';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { CustomersViewModel } from "../../ViewModel/Settings/CustomersViewModel";
import Skeleton from '@mui/material/Skeleton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Customers(){
  /* eslint-disable no-unused-vars*/
  const [
    //states
    {
      employees, localconfiguration, open, title, 
      mode, employeesFilterChosenRef, loading, disable,
      pageNumberRef, totalPage, page, code,
      pageNumber, name, Customers, loadingProcessRef,
    },
    //setStates
    {
      setEmployeesFilterChosen, 
    },
    //function
    {
      handleClickOpen, handleClose, SaveCustomer,
      handleChange, handleChangeInput, pageChange
    }
  ] = CustomersViewModel();

  const classes = useStylesEmployees();
  /* eslint-enable array-callback-return*/

  const handleClickList = (e, IsHaveSupervisor) => {
    if(!IsHaveSupervisor) {
      e.stopPropagation();
    }
  }
  
  return (
    <div>
      {Customers?
        <div style={{marginBottom:'20px'}}>
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{backgroundColor:'#f2f2f6'}}>
            <ListItem>
              <Grid container>
                <Grid item xs={10}>
                  <Typography variant="h5">Name</Typography>
                </Grid>
                <Grid item xs={2} style={{textAlign:'right',paddingRight:'40px'}}>
                  {localconfiguration.customer_settings === true?
                    <AddButton size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                      <AddIcon />
                    </AddButton>
                  :''}
                </Grid>
              </Grid>
            </ListItem>
          </List>
          {loadingProcessRef.current ?
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
            </Grid>
          :
            <>
              {Customers.map((Customer,index) => {
                const IsHaveSupervisor = Customer.managers.length > 0;
                return(
                  <CustomAccordion key={index}>
                    <CustomAccordionSummary
                      id={`customers_list_${index}`}
                      expandIcon={IsHaveSupervisor ? <ExpandMoreIcon /> : ''}
                      style={{ userSelect: 'auto', cursor: IsHaveSupervisor ? 'pointer' : 'auto',  alignItems: 'flex-start' }}
                      
                    >
                      <Grid container onClick={(event) => handleClickList(event, IsHaveSupervisor)} onFocus={(event) => event.stopPropagation()}>
                        <GridCustom item xs={6} md={6} lg={6} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                          <Typography noWrap variant="h6" id={`customers_name_${index}`}>{Customer.name}</Typography>
                        </GridCustom>
                        <Grid item xs={6} md={6} lg={6} style={{ textAlign: 'right', marginLeft: 'auto', paddingRight: IsHaveSupervisor ? '0px' : '36px' }}>
                          {localconfiguration.customer_settings ?
                            <>
                              <IconButton id={`edit_customer_${index}`} aria-label="edit" onClick={(event) => { event.stopPropagation(); handleClickOpen(Customer,'edit'); }}>
                                <EditIcon fontSize="small"/>
                              </IconButton>
                              <IconButton id={`delete_customer_${index}`} aria-label="delete" onClick={(event) => { event.stopPropagation(); handleClickOpen(Customer, 'delete'); }} >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </>
                          :
                            <IconButton id={`edit_customer_${index}`} aria-label="edit" onClick={(event) => { event.stopPropagation(); handleClickOpen(Customer,'edit'); }} >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          }
                        </Grid>
                      </Grid>
                    </CustomAccordionSummary>
                    <AccordionDetails>
                      <Grid container key={index} style={{ paddingBottom: '1px' }}>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                          {Customer.managers.length > 0 ?
                            <div>
                              <Grid container key={index}>
                                <Grid item xs={1} style={{ maxWidth: '8.333333% !important' }}> <Typography noWrap variant="h6"> Supervisors: &nbsp;</Typography></Grid>
                                <Grid item xs={11}>
                                  {/* eslint-disable-next-line array-callback-return */}
                                  {Customer.managers.map((item, childIndex) => {
                                    if (item.status === 'published' && item.employee !== null) {
                                      return (
                                        <React.Fragment key={`manager-${item.employee.user.id}-${childIndex}`}>
                                          {item.employee ?
                                            item.employee.user ?
                                              <Chip
                                                key={childIndex}
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                label={
                                                  <Typography variant="h6" id={`supervisors_${childIndex}`}>
                                                    {item.employee.user.first_name ? item.employee.user.first_name : ''} &nbsp;
                                                    {item.employee.user.last_name ? item.employee.user.last_name : ''}
                                                  </Typography>
                                                }
                                              />
                                            :
                                              <Chip
                                                key={childIndex}
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                label={
                                                  <Typography variant="h6" id={`supervisors_${childIndex}`}>
                                                    {item.employee.code ? item.employee.code : ''}
                                                  </Typography>
                                                }
                                              />
                                          :''}
                                        </React.Fragment>
                                      );
                                    }
                                  })}
                                  {/* eslint-enable-next-line array-callback-return */}
                                </Grid>
                              </Grid>
                            </div>
                            : ''}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </CustomAccordion>
                );
              })}
              <Dialog
                id="customers_dialog"
                isopen={`${open}`}
                open={open}
                fullWidth={true}
                onClose={handleClose}
              >
                <DialogTitle id="form-dialog-title"><Typography id="customers_dialog_title" component="div" variant="h5">{title}</Typography></DialogTitle>
                <DialogContent>
                  {localconfiguration.customer_settings === true?
                    mode !== 'delete'?
                    <>
                        <TextFieldCustom
                          size='small' 
                          fullWidth
                          label="Code"
                          name="code"
                          variant="outlined"
                          id="Code"
                          value={code}
                          onChange={handleChangeInput}
                        />
                        <br/>
                        <br/>
                      
                        <TextFieldCustom
                          size='small' 
                          fullWidth
                          label="Name"
                          name="name"
                          variant="outlined"
                          id="Name"
                          value={name}
                          onChange={handleChangeInput}
                        />
                        <br/>
                        <br/>

                        {employees?
                          <AutoCompleteCustom
                            size='small'
                            multiple
                            id="checkboxes-tags-demo"
                            options={employees}
                            disableCloseOnSelect
                            fullWidth={true}
                            getOptionLabel={(option) =>
                              option.user?
                              option.user.first_name+' '+
                              option.user.last_name:''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                              setEmployeesFilterChosen(newValue)
                            }}
                            value={employeesFilterChosenRef.current}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.code?option.code+' - ':''}
                                {option.user?option.user.first_name+' ':''}
                                {option.user?option.user.last_name:''}
                                
                              </React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextFieldCustom2 {...params} variant="outlined" label="Select supervisors" placeholder="Select..." />
                            )}
                          />
                        :
                          <Skeleton animation="wave" variant="rounded" height={34} style={{borderRadius:'8px'}}/>
                        }
                      </>
                    :''
                  :
                    employees?
                      <>
                      <AutoCompleteCustom
                        size='small'
                        multiple
                        id="checkboxes-tags-demo"
                        options={employees}
                        disableCloseOnSelect
                        fullWidth={true}
                        getOptionLabel={(option) => option.code}
                        getOptionSelected={(option, value) => option.id === value.id}
                        onChange={(event, newValue) => {
                          setEmployeesFilterChosen(newValue)
                        }}
                        value={employeesFilterChosenRef.current}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.code} 
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextFieldCustom2 {...params} variant="outlined" label="Select supervisors" placeholder="Select..." />
                        )}
                      />
                      </>
                    :
                      <Skeleton animation="wave" variant="rounded" height={34} style={{borderRadius:'8px'}}/>
                  }

                </DialogContent>
                <DialogActions>
                  {loading?
                    <CircularProgress className={classes.circular}/>
                  :
                    <div>
                      <CancelButton 
                        id="cancel_btn"
                        size="small" 
                        variant="contained"
                        onClick={handleClose}
                        style={{ marginRight: '5px' }}
                      >
                        Cancel
                      </CancelButton>
                      <SubmitButton
                        id={`${mode==='delete'?'delete':'save'}_btn`}
                        disabled={mode==='delete'?false:disable}
                        size="small" 
                        variant="contained"
                        onClick={()=>SaveCustomer()}
                      >
                        {mode==='delete'?'Delete':'Save'}
                      </SubmitButton>
                    </div>
                  }
                  
                </DialogActions>
              </Dialog>
            </>
          }
        </div>
      :
        <>
          <Grid container spacing={0}>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1} md={1} lg={1}>
              <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
            </CustomGridApproval>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
          </Grid>
        </>
      }

      {Customers?      
        <Grid 
          item xs={12}
          style={{textAlign:'left',marginBottom:'10px', fontFamily:'Manrope'}}
        >
          <span style={{paddingRight:'20px'}}>
            Rows per page:
          </span>
          <span style={{paddingRight:'20px'}}>
            <TextField
              select
              value={pageNumber}
              onChange={handleChange}
            >
              {pageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </span>

          <span id="page_number" style={{paddingRight:'20px'}}> 
            {(pageNumberRef.current*(page-1))+1} - {(pageNumberRef.current*page)>totalPage? totalPage : pageNumberRef.current*page} of {totalPage}
          </span>

          <Button 
            id="prev_btn"
            variant="outlined" 
            size="small" 
            disabled={page === 1} 
            className={classes.buttonLeft}
            onClick={()=>pageChange(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>

          <Button
            id="next_btn"
            variant="outlined" 
            size="small"
            className={classes.buttonRight}
            disabled={page >= Math.ceil(totalPage / pageNumberRef.current)}
            onClick={()=>pageChange(1)}
          >
            <ArrowForwardIosRoundedIcon />
          </Button>
        </Grid>
      :" "}
    </div>
  );
}