import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Button,
  Typography,
  Grid,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
} from '@material-ui/core';
import {
  CustomTooltip,
  SubmitButton,
  CancelButton,
  pageOptions,
  CustomGridApproval,
  CustomAccordion,
  CustomAccordionSummary,
  GridCustom,
  TextFieldCustom,
  FormControlCustom,
} from './style.js';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { useStylesEmployees, AddButton } from './style.js';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { IntegrationViewModel } from "../../ViewModel/Settings/IntegrationViewModel";
import Skeleton from '@mui/material/Skeleton';

export default function Integration(){
  const [
    //states
    {
      open, title, Integration,
      mode,  loading, disable,
      pageNumberRef, totalPage, page,
      pageNumber, text,
      type, environment, api_key, api_username,
      api_password, loadingProcessRef
    },
    //function
    {
      handleClickOpen, handleClose, SaveIntegration,
      handleChange, handleChangeInput, pageChange
    }
  ] = IntegrationViewModel();

  const classes = useStylesEmployees();
  /* eslint-enable array-callback-return*/


  return (
    <div>
      {Integration ?
        <div >
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: '#f2f2f6' }}>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={2}>
                      <Typography variant="h5">Type</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5">Environment</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5">API Key</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5">API Username</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5">API Password</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right', paddingRight: '40px' }}>
                      <AddButton id="add_btn" size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                        <AddIcon />
                      </AddButton>
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
          </List>

          {loadingProcessRef.current ?
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
          </Grid>
          :
          <>
            {Integration.map((data, index) => {
              return (
                <CustomAccordion key={index}>
                  <CustomAccordionSummary
                    id={`integration_list_${index}`}
                    style={{ userSelect: 'auto', cursor: 'auto', alignItems: 'flex-start' }}
                  >
                    <Grid container onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                      <GridCustom item xs={2}>
                        <Typography noWrap variant="h6" id={`integration_type_${index}`}>{data.type}</Typography>
                      </GridCustom>
                      <GridCustom item xs={2}>
                        <Typography noWrap variant="h6" id={`integration_environment_${index}`}>{data.environment}</Typography>
                      </GridCustom>
                      <GridCustom item xs={2}>
                        <CustomTooltip title={data.api_key} style={{ paddingRight: "5px" }}>
                          <Typography noWrap id={`integration_api_key_${index}`} variant="h6">{data.api_key}</Typography>
                        </CustomTooltip>
                      </GridCustom>
                      <GridCustom item xs={2}>
                        <Typography noWrap variant="h6" id={`integration_api_username_${index}`}>{data.api_username}</Typography>
                      </GridCustom>
                      <GridCustom item xs={2}>
                        <CustomTooltip title={data.api_password}>
                          <Typography noWrap id={`integration_api_password_${index}`} variant="h6">{data.api_password}</Typography>
                        </CustomTooltip>
                      </GridCustom>
                      <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <IconButton id={`edit_btn_${index}`} aria-label="edit" onClick={() => handleClickOpen(data, 'edit')} >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton id={`delete_btn_${index}`} aria-label="delete" onClick={() => handleClickOpen(data, 'delete')} >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CustomAccordionSummary>
                </CustomAccordion>
              );
            })}
            <Dialog
              id="integration_dialog"
              isopen={`${open}`}
              open={open}
              fullWidth={true}
              onClose={handleClose}
            >
              <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="integration_dialog_title" component="div" variant="h6"><h2>{title}</h2></Typography></DialogTitle>
              <DialogContent>
                <Typography variant="h6">{text ? text : ''}</Typography>
                {mode !== 'delete' ?
                  <div>
                    <FormControlCustom fullWidth size='small' sx={{ marginBottom: '8px' }}>
                      <InputLabel>Type</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="type_select"
                        value={type}
                        label="Type"
                        name="type"
                        aria-label='Type'
                        defaultValue={'bc365'}
                        onChange={handleChangeInput}
                      >
                        <MenuItem value={'bc365'}>Bussiness Central 365</MenuItem>
                      </Select>
                    </FormControlCustom>
                    <TextFieldCustom
                      size='small'
                      id="environment_text"
                      label="Environment"
                      variant="outlined"
                      fullWidth={true}
                      margin="normal"
                      name="environment"
                      value={environment}
                      onChange={handleChangeInput}
                    />         
                    <TextFieldCustom
                      size='small'
                      id="api_key_text"
                      label="API key"
                      variant="outlined"
                      fullWidth={true}
                      margin="normal"
                      name="api_key"
                      value={api_key}
                      onChange={handleChangeInput}
                    />         
                    <TextFieldCustom
                      size='small'
                      id="api_username_text"
                      label="API username"
                      variant="outlined"
                      fullWidth={true}
                      margin="normal"
                      name="api_username"
                      value={api_username}
                      onChange={handleChangeInput}
                    />   
                    <TextFieldCustom
                      size='small'
                      id="api_password_text"
                      label="API password"
                      variant="outlined"
                      fullWidth={true}
                      margin="normal"
                      name="api_password"
                      value={api_password}
                      onChange={handleChangeInput}
                    />                  
                  </div>
                  : ''}
              </DialogContent>
              <DialogActions style={{ height: "60px" }}>
                {loading?
                  <CircularProgress className={classes.circular} />
                  :
                  <div>
                    <CancelButton
                      id="cancel_btn"
                      size="small"
                      variant="contained"
                      onClick={handleClose}
                      style={{ marginRight: '5px' }}
                    >
                      Cancel
                    </CancelButton>
                    <SubmitButton
                      id="save_btn"
                      disabled={mode === 'delete' ? false : disable}
                      size="small"
                      variant="contained"
                      onClick={() => SaveIntegration()}
                    >
                      {mode === 'delete' ? 'Delete' : 'Save'}
                    </SubmitButton>
                  </div>
                }

              </DialogActions>
            </Dialog>
          </>
          }
        </div>
        :
        <>
          <Grid container spacing={0} style={{ marginBottom: '10px'}}>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
              <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
            </CustomGridApproval>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
              <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
            </CustomGridApproval>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
              <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
            </CustomGridApproval>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
              <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
            </CustomGridApproval>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
              <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
            </CustomGridApproval>
            <CustomGridApproval style={{ whiteSpace: 'nowrap', marginLeft: 'auto', marginRight: '40px' }} item>
              <Skeleton variant="circular" height={30} width={30}/>
            </CustomGridApproval>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
          </Grid>
        </>
      }

      {Integration?
        <Grid
          item xs={12}
          style={{ textAlign: 'left', marginBottom: '10px', fontFamily: 'Manrope' }}
        >
          <span style={{ paddingRight: '20px' }}>
            Rows per page:
          </span>
          <span style={{ paddingRight: '20px' }}>
            <TextField
              id="standard-select-currency"
              select
              value={pageNumber}
              onChange={handleChange}
            >
              {pageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </span>

          <span id="page_number" style={{ paddingRight: '20px' }}>
            {(pageNumberRef.current * (page - 1)) + 1}- {(pageNumberRef.current * page) > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
          </span>

          <Button
            id="prev_btn"
            variant="outlined"
            size="small"
            disabled={page === 1}
            className={classes.buttonLeft}
            onClick={() => pageChange(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>

          <Button
            id="next_btn"
            variant="outlined"
            size="small"
            className={classes.buttonRight}
            disabled={page >= Math.ceil(totalPage / pageNumberRef.current)}
            onClick={() => pageChange(1)}
          >
            <ArrowForwardIosRoundedIcon />
          </Button>
        </Grid>
        :
        " "}
    </div>
  );
}