import React from 'react';
import { 
  Checkbox,
} from '@material-ui/core';
import {
  AutoCompleteCustom,
  TextFieldCustom2,
} from './style.js';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Skeleton from '@mui/material/Skeleton';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SelectEmployee = (props) => {

  return (
    <div>
      {props.employeesFilterData ?
          <AutoCompleteCustom
            size='small'
            multiple
            data-testid='autocomplete-search'
            id="add_new_timesheet"
            options={props.employeesFilterData}
            disableCloseOnSelect
            getOptionLabel={(option) => option.user.first_name + ' ' + option.user.last_name}
            onChange={(event, newValue) => {
              props.setEmployeesFilterChosen(newValue)
            }}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.code} - {option.user.first_name} {option.user.last_name}
              </React.Fragment>
            )}
            style={{ width: 500 }}
            renderInput={(params) => (
              <TextFieldCustom2 {...params} size='small' variant="outlined" label="Select employees to add" placeholder="Select..." />
            )}
          />
        : <Skeleton animation="wave" variant="rounded" height={34} style={{ borderRadius:'8px', width: 500 }}/>
      }
    </div>
  );
};

export default SelectEmployee;
