import { useState, useEffect } from 'react';
import { ConfigurationController , TimezoneListController } from '../../Controller/Settings/Configuration.js';
import directus from '../../services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import useStateRef from "react-usestateref";
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../views/Components/ErrorMessage';

export const ConfigurationViewModel = () => {
  /* eslint-disable no-unused-vars*/
  const [initialized, setInitialized] = useState(false);
  const [Configuration, setConfiguration, configurationRef] = useStateRef(null);
  const [searchInt, setSearchInt] = useState('');
  const [maxBreakTime, setMaxBreakTime, maxBreakTimeRef] = useStateRef('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timezone, setTimezone, timezoneRef] = useStateRef('');
  const [timezoneFilterChosen, setTimezoneFilterChosen, timezoneFilterChosenRef] = useStateRef('');
  const [emailNotificationStatus, setEmailNotificationStatus, emailNotificationStatusRef] = useStateRef(false);
  const [checked, setChecked, checkedRef] = useStateRef(false);
  const { enqueueSnackbar } = useSnackbar();
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadConfiguration();
    }

    const searchIntegration = (eventData) => {
      setSearchInt(eventData.text);
      loadConfiguration(eventData.text);
    }

    const listener = EventEmitter.addListener('searchIntegration', searchIntegration);

    return () => {
      listener.remove();
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadConfiguration = async () => {
    var ConfigurationResults = await ConfigurationController();
    setConfiguration(ConfigurationResults.data[0]);
    setMaxBreakTime(ConfigurationResults.data[0].max_break_time);
    setTimezoneFilterChosen({ id: 0, name: ConfigurationResults.data[0].timezone, value: ConfigurationResults.data[0].timezone });
    setEmailNotificationStatus(ConfigurationResults.data[0].enable_email_notifications);
    setChecked(ConfigurationResults.data[0].enable_leave_supervisor);
    setTimezone(await TimezoneListController());
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === 'max_break_time') {
      setMaxBreakTime(value);
    }
  }
  const switchHandleChange = (event) => {
    setEmailNotificationStatus(event.target.checked);
  };

  const toggleChecked = (event) => {
    setChecked(event.target.checked);
  };
  
  const SaveConfiguration = async () => {
    setLoading(true);
    try {
      let updateResult = await directus.updateItem('configuration', configurationRef.current.id,{
        max_break_time: maxBreakTimeRef.current ? maxBreakTimeRef.current : '',
        timezone: timezoneFilterChosenRef.current.value,
        enable_email_notifications:emailNotificationStatusRef.current,
        enable_leave_supervisor:checkedRef.current,
      });
      localStorage.setItem("configuration", JSON.stringify(updateResult.data));
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    loadConfiguration();
    setLoading(false);
    handleClose();
  }

  return[
    //states
    {
      configurationRef, maxBreakTimeRef, timezoneRef, timezoneFilterChosenRef,
      emailNotificationStatusRef, checkedRef, loading
    },
    //setStates
    {
      setTimezoneFilterChosen,
    },
    //function
    {
      handleChangeInput, switchHandleChange, toggleChecked, SaveConfiguration
    }
  ];
}

export default ConfigurationViewModel;