import React from 'react';
import {
  Button,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import directus from '../../../services/directus';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../../views/Components/ErrorMessage';
import dayjs from 'dayjs';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import EventEmitter from 'src/utils/EventEmitter';
import {
  useStylesEditSession,
  CancelButton,
  SaveButton,
} from './style.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { TimeRange, TimeRelation } from 'datetime-period';

export default function SaveAddSession(props) {
  const classes = useStylesEditSession();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(0);

  var disabledCheck=false;

  if(!props.session.data)
  {
    var disabledCheck=true;
  }
  else
  {  
    if(!props.session.checkbox_full_day && props.session.checkbox_public_holiday)
    {
      if(props.session.data.show_start_end_time){
        disabledCheck=props.startTime === "Invalid Date" || props.endTime === "Invalid Date" ;
      }
      
      if(props.session.data.show_duration){
        disabledCheck=props.sessionDuration === null || parseTimeSpan(props.sessionDuration) === 0;
      }
      
      if(props.session.data.show_start_end_time && props.session.data.show_duration){      
        disabledCheck=(props.startTime === "Invalid Date" || props.endTime === "Invalid Date" ) && (props.sessionDuration===null || parseTimeSpan(props.sessionDuration) === 0);
      }
    }
    else
    {

      disabledCheck=false;
    }
  }

  const saveAddSessionDrawer = (text) => {
    EventEmitter.emit('saveAddSessionDrawer', {
      text: text,
      info: 'addsession'
    })
  }

  const dashboardDrawer = (data) => {

    window.DashboardGlobal = false;
    setOpenDrawer(0);

    EventEmitter.emit('contentWidth', {
      data: window.DashboardGlobal
    })

    EventEmitter.emit('dashboardDrawer', {
      text: data,
    })
  }

  function parseTimeSpan(timeString) {
    if (timeString !== null) {
      let parts = timeString?timeString.split("h"):'';
      let parts2 = timeString?timeString.split("m"):'';
      if (parts.length > 1 || parts2.length > 1) {
        if (parts.length === 1) {
          return (parseInt(parts[0].slice(0, -1), 10))
        }
        else {
          return (parseInt(parts[0], 10) * 60) + (parseInt(parts[1].slice(0, -1) | 0, 10))
        }
      }
      else {
        var decimalTimeString = timeString;
        var n = new Date(0, 0);
        n.setMinutes(+decimalTimeString * 60);
        return ((moment(n).hour() * 60) + moment(n).minute());
      }
    }
    else {
      return 0;
    }
  }


  function checkPMAM(startTime, EndTime) {
    var startHours = startTime.slice(0, 2);
    var endHours = EndTime.slice(0, 2);

    if (startHours >= 12 && endHours <= 12) {
      return true;
    }
    else {
      return false;
    }
  }

  const SessionAdd = async () => {
    setLoading(true);

    var SessionsFilter = [];
    let addData = [];
    var StartInside = [];
    var EndInside = [];
    var InsideStartTouching = [];
    var InsideEndTouching = [];
    var Enclosing = [];
    var EnclosingStartTouching = [];
    var EnclosingEndTouching = [];
    var Inside = [];
    var AddInside = [];
    var ExactMatch = [];
    var session_date = '';

    // ============Overlap========================//
    if (props.session.data.show_start_end_time) {
      var startHours = props.startTime.slice(0, 2);
      var endHours = props.endTime.slice(0, 2);

      SessionsFilter = await directus.getItems('sessions', {
        fields: '*',
        filter:
        {
          employee: { eq: props.employeeId },
          session_date: { in: [dayjs(props.selectedDate).format('YYYY-MM-DD'), dayjs(props.selectedDate).add(1, 'day').format('YYYY-MM-DD'), dayjs(props.selectedDate).add(-1, 'day').format('YYYY-MM-DD')] },
        },
        limit: -1,
      });

      if (checkPMAM(startHours, endHours)) {
        var timeRangeB = new TimeRange((dayjs(props.selectedDate).format('YYYY-MM-DD') + ' ' + props.startTime), ((dayjs(props.selectedDate).add(1, 'day').format('YYYY-MM-DD')) + ' ' + props.endTime));

        if (SessionsFilter.data.length > 0) {
          SessionsFilter.data.map((data, index) => {
            if (checkPMAM(data.session_start_time ? data.session_start_time.slice(0, 2) : '', data.session_end_time ? data.session_end_time.slice(0, 2) : '')) {
              session_date = dayjs(data.session_date).add(1, 'day').format('YYYY-MM-DD');
            }
            else {
              session_date = data.session_date;
            }

            if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'StartInside') {
              StartInside = [...StartInside, { id: data.id, session_end_time: props.startTime }];
            }

            if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'EndInside') {
              EndInside = [...EndInside, { id: data.id, session_start_time: props.endTime }];
            }

            if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'InsideStartTouching') {
              InsideStartTouching = [...InsideStartTouching, { id: data.id, session_start_time: props.endTime }]
            }

            if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'InsideEndTouching') {
              InsideEndTouching = [...InsideEndTouching, { id: data.id, session_end_time: props.startTime }]
            }

            if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'EnclosingStartTouching') {
              EnclosingStartTouching = [...EnclosingStartTouching, data.id];
            }

            if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'EnclosingEndTouching') {
              EnclosingEndTouching = [...EnclosingEndTouching, data.id];
            }

            if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'Enclosing') {
              Enclosing = [...Enclosing, data.id];
            }

            if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'Inside') {
              Inside = [...Inside, { id: data.id, session_end_time: props.startTime }];

              AddInside = [...AddInside,
              {
                activity: data.activity,
                break_end_time: data.break_end_time,
                break_start_time: data.break_start_time,
                break_time: data.break_time,
                customer: data.customer,
                duration: data.duration,
                employee: data.employee,
                end_time: data.end_time,
                entry_number2: data.entry_number2,
                leave_note: data.leave_note,
                leave_status: data.leave_status,
                notes: data.notes,
                owner: data.owner,
                session_date: session_date,
                session_end_time: data.session_end_time,
                session_start_time: props.endTime,
                session_type: data.session_type,
                timesheet: data.timesheet,
                type: data.type,
                status: data.status,
              }];
            }

            if (new TimeRelation(timeRangeB, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'ExactMatch') {
              ExactMatch = [...ExactMatch, data.id];
            }
          })


          if (ExactMatch.length > 0) {
            try {
              await directus.deleteItems('sessions', ExactMatch);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (StartInside.length > 0) {
            try {
              await directus.updateItems('sessions', StartInside);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (EndInside.length > 0) {
            try {
              await directus.updateItems('sessions', EndInside);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (InsideStartTouching.length > 0) {
            try {
              await directus.updateItems('sessions', InsideStartTouching);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (InsideEndTouching.length > 0) {
            try {
              await directus.updateItems('sessions', InsideEndTouching);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (EnclosingStartTouching.length > 0) {
            try {
              await directus.deleteItems('sessions', EnclosingStartTouching);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (EnclosingEndTouching.length > 0) {
            try {
              await directus.deleteItems('sessions', EnclosingEndTouching);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (Enclosing.length > 0) {
            try {
              await directus.deleteItems('sessions', Enclosing);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (Inside.length > 0) {
            try {
              await directus.updateItems('sessions', Inside);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (AddInside.length > 0) {
            try {
              await directus.createItems('sessions', AddInside);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }
        }
      }
      else {
        var timeRangeA = new TimeRange((dayjs(props.selectedDate).format('YYYY-MM-DD') + ' ' + props.startTime), ((dayjs(props.selectedDate).format('YYYY-MM-DD')) + ' ' + props.endTime));

        if (SessionsFilter.data.length > 0) {
          SessionsFilter.data.map((data, index) => {
            if (checkPMAM(data.session_start_time ? data.session_start_time.slice(0, 2) : '', data.session_end_time ? data.session_end_time.slice(0, 2) : '')) {
              session_date = dayjs(data.session_date).add(1, 'day').format('YYYY-MM-DD');
            }
            else {
              session_date = data.session_date;
            }            

            if (new TimeRelation(timeRangeA, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'StartInside') {
              StartInside = [...StartInside, { id: data.id, session_end_time: props.startTime }];
            }

            if (new TimeRelation(timeRangeA, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'EndInside') {
              EndInside = [...EndInside, { id: data.id, session_start_time: props.endTime }];
            }

            if (new TimeRelation(timeRangeA, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'InsideStartTouching') {
              InsideStartTouching = [...InsideStartTouching, { id: data.id, session_start_time: props.endTime }]
            }

            if (new TimeRelation(timeRangeA, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'InsideEndTouching') {
              InsideEndTouching = [...InsideEndTouching, { id: data.id, session_end_time: props.startTime }]
            }

            if (new TimeRelation(timeRangeA, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'EnclosingStartTouching') {
              EnclosingStartTouching = [...EnclosingStartTouching, data.id];
            }

            if (new TimeRelation(timeRangeA, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'EnclosingEndTouching') {
              EnclosingEndTouching = [...EnclosingEndTouching, data.id];
            }

            if (new TimeRelation(timeRangeA, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'Enclosing') {
              Enclosing = [...Enclosing, data.id];
            }

            if (new TimeRelation(timeRangeA, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'Inside') {
              Inside = [...Inside, { id: data.id, session_end_time: props.startTime }];

              AddInside = [...AddInside,
              {
                activity: data.activity,
                break_end_time: data.break_end_time,
                break_start_time: data.break_start_time,
                break_time: data.break_time,
                customer: data.customer,
                duration: data.duration,
                employee: data.employee,
                end_time: data.end_time,
                entry_number2: data.entry_number2,
                leave_note: data.leave_note,
                leave_status: data.leave_status,
                notes: data.notes,
                owner: data.owner,
                session_date: session_date,
                session_end_time: data.session_end_time,
                session_start_time: props.endTime,
                session_type: data.session_type,
                timesheet: data.timesheet,
                type: data.type,
                status: data.status,
              }];
            }

            if (new TimeRelation(timeRangeA, new TimeRange((data.session_date + ' ' + data.session_start_time), (session_date + ' ' + data.session_end_time))).getRelation() === 'ExactMatch') {
              ExactMatch = [...ExactMatch, data.id ];
            }
          })

          if (ExactMatch.length > 0) {
            try {
              await directus.deleteItems('sessions', ExactMatch);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (StartInside.length > 0) {
            try {
              await directus.updateItems('sessions', StartInside);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (EndInside.length > 0) {
            try {
              await directus.updateItems('sessions', EndInside);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (InsideStartTouching.length > 0) {
            try {
              await directus.updateItems('sessions', InsideStartTouching);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (InsideEndTouching.length > 0) {
            try {
              await directus.updateItems('sessions', InsideEndTouching);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (EnclosingStartTouching.length > 0) {
            try {
              await directus.deleteItems('sessions', EnclosingStartTouching);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (EnclosingEndTouching.length > 0) {
            try {
              await directus.deleteItems('sessions', EnclosingEndTouching);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (Enclosing.length > 0) {
            try {
              await directus.deleteItems('sessions', Enclosing);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (Inside.length > 0) {
            try {
              await directus.updateItems('sessions', Inside);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }

          if (AddInside.length > 0) {
            try {
              await directus.createItems('sessions', AddInside);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }
        }
      }
    }

      addData = {
        timesheet: props.timesheetId,
        session_type: props.type.data.id,
        employee: props.employeeId,
        session_date: dayjs(props.selectedDate).format('YYYY-MM-DD'),
        notes: props.sessionNotes,
      }

    if (props.session.data.show_activity && props.activity) {
      addData = {
        ...addData,
        activity: props.activity.id,
      };
    }
    if (props.session.data.show_customer && props.customer) {
      addData = {
        ...addData,
        customer: props.customer.id,
      };
    }

    if((props.session.checkbox_full_day || !props.session.checkbox_public_holiday) )
    {
      if((props.startTime === "Invalid Date" && props.endTime === "Invalid Date")||(props.startTime === null && props.endTime === null))
      {
        addData={
          ...addData,
          duration : props.session.data.default_duration,
          session_start_time: null,
          session_end_time: null,
          break_start_time: null,
          break_end_time: null,
        }
      }
    }

    if (props.session.data.show_break_time && props.sessionBreakTime) {
      addData = {
        ...addData,
        break_time: props.sessionBreakTime,
      };
    }

    if (props.session.data.show_break_start_end_time && (props.breakStartTime !== null || props.breakEndTime !==null) && (props.breakStartTime !== 'Invalid Date' || props.breakEndTime !=='Invalid Date')) {
      addData = {
        ...addData,
        break_start_time: props.breakStartTime !== 'Invalid Date' ? props.breakStartTime : null,
        break_end_time: props.breakEndTime !== 'Invalid Date' ? props.breakEndTime : null,
      };
    }

    if (props.session.data.show_start_end_time && props.session.data.show_duration) {

      if (parseTimeSpan(props.sessionDuration) !== 0) {
        addData = {
          ...addData,
          duration: parseTimeSpan(props.sessionDuration),
          session_start_time: null,
          session_end_time: null,
        };
      }
      else {
        addData = {
          ...addData,
          duration: null,
          session_start_time: props.startTime !== 'Invalid Date' ? props.startTime : null,
          session_end_time: props.endTime !== 'Invalid Date' ? props.endTime : null,
        };
      }
    }
    else {
      if (props.session.data.show_start_end_time) {
        addData = {
          ...addData,
          session_start_time: props.startTime !== 'Invalid Date' ? props.startTime : null,
          session_end_time: props.endTime !== 'Invalid Date' ? props.endTime : null,
        };
      }

      if (props.session.data.show_duration) {
        addData = {
          ...addData,
          duration: parseTimeSpan(props.sessionDuration),
        };
      }
    }

    
    var createSession;

    if (addData) {
      try {
        createSession = await directus.createItems('sessions', addData);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }
    createResources(createSession.data.id);
    uploadFileDescription(createSession.data.id);
    createSessionOptions(createSession.data.id);
    saveAddSessionDrawer(true);
    setLoading(false);
  }

  const createResources = async (sessionID) => {
    //==========Material==========//
    let addMaterial = [];

    if (props.allmaterialData) {
      props.allmaterialData.map((data) => {
        if (data.value === 2 && data.name) {
          addMaterial = [...addMaterial, { session: sessionID, resource: data.id, type: 'chemical', quantity: data.quantity }];
        }
      })

      if (addMaterial.length > 0) {
        try {
          await directus.createItems('sessions_resources', addMaterial);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
    }

    //==========Equipment==========//
    let addEquipment = [];
    if (props.allEquipmentData) {
      props.allEquipmentData.map((data) => {
        if (data.value === 2 && data.name) {
          addEquipment = [...addEquipment, { session: sessionID, resource: data.id, type: 'equipment', quantity: 0 }];
        }
      })

      if (addEquipment.length > 0) {
        try {
          await directus.createItems('sessions_resources', addEquipment);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
    }
  }

  const uploadFileDescription = async (sessionID) => {
    if (props.fileUpload) {
      if (props.fileUpload.length > 0) {
        const form = document.querySelector("#my-form");
        const data = new FormData(form);

        let uploadFile = await directus.uploadFiles(data, onUploadProgress);
        try {
          await directus.createItem('sessions_directus_files', {
            session: sessionID,
            directus_file: uploadFile.data.id
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
    }
  }

  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + "% Done");
  }


  const createSessionOptions= async (sessionID) => {

    let OptionsData = [];
    
    props.optionsList.map((options) => {
      if(options.type !== "4" && options.type !== "3" && options.type !== "7")
      {
        OptionsData = [...OptionsData, 
          { session:sessionID, 
            status:'published', 
            session_option:options.id, 
            value: options.value === null?options.data.input_type==='checkbox'?'false':0 :options.value}];
      }
    })

    if(OptionsData.length > 0)
    {
      try
      {
        await directus.createItems("sessions_session_options", OptionsData);
      }
      catch(e)
      {
        enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
      }  
    }
  }

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 1 }} open={loading}>
        <CircularProgress style={{ color: '#FA9917' }} />
      </Backdrop>

      <CancelButton
        id="submit_for_approval_btn"
        size="small"
        fullWidth
        onClick={() => dashboardDrawer(6)}
      >
        <Typography className={classes.fontstyle6}>Cancel</Typography>
      </CancelButton>

      <SaveButton
        id="submit_for_approval_btn"
        size="small"
        fullWidth
        onClick={() => SessionAdd()}
        disabled={disabledCheck || loading} 
      >
        <Typography className={classes.fontstyle6}>Add Session</Typography>
      </SaveButton>
    </>
  );
}
