import React, { useState, useEffect } from 'react';
import { 
  SessionOptionController,
  AwardsController,
  CustomersController,
  SessionTypesController,
  SessionOptionListController,
 } from '../../Controller/Settings/SessionOption';
import directus from '../../services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import useStateRef from "react-usestateref";
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../views/Components/ErrorMessage';
import linq from "linq";

let page = 1;

export const SessionOptionViewModel = () => {
  const [initialized, setInitialized] = useState(false);
  const [searchSesOp, setSearchSesOp ] = useState('');

  const [sessionOptions, setSessionOptions] = useState(null);
  const [sessionOptionsData, setSessionOptionsData] = useState(null);

  const [sort, setSort] = useState(''); 
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [question, setQuestion] = useState('');

  const [inputType, setInputType] = useState('');
  const [criteria, setCriteria] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [onlyOvertime, setOnlyOvertime, onlyOvertimeRef] = useStateRef(false);
  const [afterOvertime, setAfterOvertime] = useState('');
  const [employeeSetting, setEmployeeSetting, employeeSettingRef] = useStateRef(false);
  const [invertOutput, setInvertOutput, invertOutputRef] = useStateRef(false);  

  const [onlyRosteredDays, setOnlyRosteredDays, onlyRosteredDaysRef] = useStateRef(false);  

  const [awards, setAwards, awardsRef] = useStateRef('');
  const [awardsFilterChosen, setAwardsFilterChosen, awardsFilterChosenRef] = useStateRef('');
  
  const [customers, setCustomers, customersRef] = useStateRef('');
  const [customersFilterChosen, setCustomersFilterChosen, customersFilterChosenRef] = useStateRef('');

  const [sessionTypes, setSessionTypes, sessionTypesRef] = useStateRef('');
  const [sessionTypesFilterChosen, setSessionTypesFilterChosen, sessionTypesFilterChosenRef] = useStateRef('');

  const [payrun, setPayrun, payrunRef] = useStateRef('');

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');

  const [mode, setMode] = useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingProcess, setLoadingProcess, loadingProcessRef] = useStateRef(false);

  const [totalPage, setTotalPage] = useState("");

  const [hideIfOptionSet, setHideIfOptionSet] = useState("");
  const [hideIfOptionSetChosen, setHideIfOptionSetChosen, hideIfOptionSetChosenRef] = useStateRef('');

  const [onlyIfOptionSet, setOnlyIfOptionSet] = useState('');

  const [pageNumber, setPageNumber,pageNumberRef] = useStateRef(localStorage.getItem("session_options_page")?localStorage.getItem("session_options_page"):50);

  const { enqueueSnackbar } = useSnackbar();

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadSessionOptions();
    }

    const searchSessionOptions = (eventData) => {
      setLoadingProcess(true);
      page = 1;
      setSearchSesOp(eventData.text);
      loadSessionOptions(eventData.text);
    }

    const listener = EventEmitter.addListener('searchSessionOptions', searchSessionOptions);
    
    return () => {
      listener.remove();
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  let disable = code === '' || name === '';
  let localconfiguration = JSON.parse(window.localStorage.getItem('configuration'));

  const loadSessionOptions = async (data) => {
    var SessionOptionResults = await SessionOptionController(data,page);
    setSessionOptions(SessionOptionResults.data);
    setTotalPage(SessionOptionResults.meta.filter_count);
 
    var Awards = await AwardsController();
    setAwards(Awards.data);

    var Customers = await CustomersController();
    setCustomers(Customers.data);

    var SessionTypes = await SessionTypesController();
    setSessionTypes(SessionTypes.data);

    var SessionOptionList = await SessionOptionListController();
    setHideIfOptionSet(SessionOptionList.data);
    setLoadingProcess(false);
  };

  const pageChange = (value) => {
    setLoadingProcess(true);
    page = page + value;
    loadSessionOptions(searchSesOp);
  }

  const handleChange = (event) => {
    setLoadingProcess(true);
    setPageNumber(event.target.value);
    localStorage.setItem("session_options_page", JSON.parse(event.target.value));
    page = 1;
    loadSessionOptions(searchSesOp);
  };

  const handleClickOpen = (SessionOptions, mode) => {
    setOpen(true);
    if (mode === 'edit') {
      setSort(SessionOptions.sort);
      setCode(SessionOptions.code);
      setName(SessionOptions.name);
      setQuestion(SessionOptions.description);
      setInputType(SessionOptions.input_type);
      setCriteria(SessionOptions.criteria);
      setDefaultValue(SessionOptions.default_value);
      setOnlyIfOptionSet(SessionOptions.only_if_option_set);
      setOnlyOvertime(SessionOptions.only_overtime);
      setAfterOvertime(SessionOptions.after_overtime);
      setEmployeeSetting(SessionOptions.employee_setting);
      setInvertOutput(SessionOptions.invert_output);
      setOnlyRosteredDays(SessionOptions.only_rostered_days);

      if(SessionOptions.awards.length > 0)
      {
        let result = [];
        SessionOptions.awards.map(data => {
          result = [...result, data.awards_id];
        })
        setAwardsFilterChosen(result);
      }
      else
      { setAwardsFilterChosen([]);}

      if(SessionOptions.customers.length > 0)
      {
        let result = [];
        SessionOptions.customers.map(data => {
          result = [...result, data.customers_id];
        })
        setCustomersFilterChosen(result);
      }
      else
      { setCustomersFilterChosen([]);}

      if(SessionOptions.session_types.length > 0)
      {
        let result = [];
        SessionOptions.session_types.map(data => {
          result = [...result, data.session_types_id];
        })
        setSessionTypesFilterChosen(result);
      }
      else
      { setSessionTypesFilterChosen([]);}

      setHideIfOptionSetChosen(SessionOptions.hide_if_option_set);

      setSessionOptionsData(SessionOptions);
      setMode('edit')
      setTitle('Edit Session Options');
      setText('');
    }
    else if (mode === 'add') {
      setSort('');
      setCode('');
      setName('');
      setQuestion('');
      setInputType('checkbox');
      setCriteria('');
      setDefaultValue('');
      setOnlyIfOptionSet('');
      setOnlyOvertime(false);
      setAfterOvertime('');
      setEmployeeSetting(false);
      setInvertOutput(false);
      setOnlyRosteredDays(false);
      setHideIfOptionSetChosen(null);

      setAwardsFilterChosen([]);
      setCustomersFilterChosen([]);
      setSessionTypesFilterChosen([]);

      setMode('add');
      setTitle('Add Session Options');
      setText('');
    }
    else {
      setSessionOptionsData(SessionOptions);
      setMode('delete');
      setTitle('Delete Session Options');
      setText('Are you Sure?');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === 'sort') {
      setSort(value);
    }
    else if (name === 'code') {
      setCode(value);
    }
    else if (name === 'name') {
      setName(value);
    }
    else if (name === 'question') {
      setQuestion(value);
    }
    else if (name === 'input_type') {
      setInputType(value);
    }
    else if (name === 'criteria') {
      setCriteria(value);
    }
    else if (name === 'default_value') {
      setDefaultValue(value);
    }
    else if (name === 'only_if_option_set') {
      setOnlyIfOptionSet(value);
    }
    else if (name === 'only_overtime') {
      setOnlyOvertime(e.target.checked);
    }
    else if (name === 'after_overtime') {
      setAfterOvertime(value);
    }
    else if (name === 'employee_setting') {
      setEmployeeSetting(e.target.checked);
    }
    else if (name === 'invert_output') {
      setInvertOutput(e.target.checked);
    }
    else if (name === 'only_rostered_days') {
      setOnlyRosteredDays(e.target.checked);
    }
  }

  const SaveSessionOptions = async () => {
    setLoading(true);
    setLoadingProcess(true);

    if (mode === 'edit') 
    {
      try {
        await directus.updateItem('session_options', sessionOptionsData.id, {
          sort:sort?sort:'',
          code:code?code:'',
          name:name?name:'',
          description:question?question:'',
          input_type:inputType?inputType:'',
          criteria:criteria?criteria:'',
          default_value:defaultValue?defaultValue.toLowerCase():'',
          only_if_option_set:onlyIfOptionSet?onlyIfOptionSet:'',
          only_overtime:onlyOvertimeRef.current?onlyOvertimeRef.current:'',
          after_overtime:onlyOvertimeRef.current?afterOvertime?afterOvertime:'':0,
          employee_setting:employeeSettingRef.current?employeeSettingRef.current:'',
          invert_output:invertOutputRef.current?invertOutputRef.current:'',
          only_rostered_days:onlyRosteredDaysRef.current?onlyRosteredDaysRef.current:'',
          hide_if_option_set:hideIfOptionSetChosenRef.current?hideIfOptionSetChosenRef.current:'',
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      let createAwardsData = [];
      //================================== Add Session Options Awards Relation ===================================//
      awardsFilterChosenRef.current.map(data => {
        const filterInput = linq.from(sessionOptionsData.awards)
          .where(x=>x.awards_id.id===data.id).toArray();

        if (filterInput.length === 0) {
          createAwardsData = [...createAwardsData, { session_options_id:sessionOptionsData.id, awards_id:data.id, status:'published'}];
        }
      })

      if (createAwardsData.length > 0){
        try {
          await directus.createItems('session_options_awards', createAwardsData);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      let deleteAwardsData = "";
      //================================== Delete Session Options Awards Relation ===================================//
      sessionOptionsData.awards.map(data => {
        const filterInput = linq.from(awardsFilterChosenRef.current)
          .where(x=>x.id === data.awards_id.id).toArray();

        if(!!sessionOptionsData.awards)
        {
          if (filterInput.length === 0) {
            if (deleteAwardsData === '') {
              deleteAwardsData = data.awards_id.id;
            }
            else {
              deleteAwardsData = data.awards_id.id + ',' + deleteAwardsData;
            }
          }
        }
      })
  
      if (deleteAwardsData !== '') {
        var SessionOptionsAwardsResult = await directus.getItems('session_options_awards', {
          fields: '*',
          filter: {
            session_options_id: { eq: sessionOptionsData.id },
            awards_id: { in: deleteAwardsData },
          }
        });

        let deleteAwardsInput = [];

        SessionOptionsAwardsResult.data.map(data => {
          deleteAwardsInput = [...deleteAwardsInput, data.id];
        })

        try {
          await directus.deleteItems('session_options_awards', deleteAwardsInput);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      let createCustomersData = [];
      //================================== Add Session Options Customers Relation ===================================//
      customersFilterChosenRef.current.map(data => {
        const filterInput = linq.from(sessionOptionsData.customers)
          .where(x=>x.customers_id.id===data.id).toArray();

        if (filterInput.length === 0) {
          createCustomersData = [...createCustomersData, { session_options_id:sessionOptionsData.id, customers_id:data.id, status:'published'}];
        }
      })

      if (createCustomersData.length > 0){
        try {
          await directus.createItems('session_options_customers', createCustomersData);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      let deleteCustomersData = "";
      //================================== Delete Session Options Customers Relation ===================================//
      sessionOptionsData.customers.map(data => {
        const filterInput = linq.from(customersFilterChosenRef.current)
          .where(x=>x.id === data.customers_id.id).toArray();

        if(!!sessionOptionsData.customers)
        {
          if (filterInput.length === 0) {
            if (deleteCustomersData === '') {
              deleteCustomersData = data.customers_id.id;
            }
            else {
              deleteCustomersData = data.customers_id.id + ',' + deleteCustomersData;
            }
          }
        }
      })

      if (deleteCustomersData !== '') {
        var SessionOptionsCustomersResult = await directus.getItems('session_options_customers', {
          fields: '*',
          filter: {
            session_options_id: { eq: sessionOptionsData.id },
            customers_id: { in: deleteCustomersData },
          }
        });

        let deleteCustomersInput = [];

        SessionOptionsCustomersResult.data.map(data => {
          deleteCustomersInput = [...deleteCustomersInput, data.id];
        })

        try {
          await directus.deleteItems('session_options_customers', deleteCustomersInput);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }


      let createSessionTypesData = [];
      //================================== Add Session Options Session Types Relation ===================================//
      sessionTypesFilterChosenRef.current.map(data => {
        const filterInput = linq.from(sessionOptionsData.session_types)
          .where(x=>x.session_types_id.id===data.id).toArray();

        if (filterInput.length === 0) {
          createSessionTypesData = [...createSessionTypesData, { session_options_id:sessionOptionsData.id, session_types_id:data.id, status:'published'}];
        }
      })

      if (createSessionTypesData.length > 0){
        try {
          await directus.createItems('session_options_session_types', createSessionTypesData);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      let deleteSessionTypesData = "";
      //================================== Delete Session Options Session Types Relation ===================================//
      sessionOptionsData.session_types.map(data => {
        const filterInput = linq.from(sessionTypesFilterChosenRef.current)
          .where(x=>x.id === data.session_types_id.id).toArray();

        if(!!sessionOptionsData.session_types)
        {
          if (filterInput.length === 0) {
            if (deleteSessionTypesData === '') {
              deleteSessionTypesData = data.session_types_id.id;
            }
            else {
              deleteSessionTypesData = data.session_types_id.id + ',' + deleteSessionTypesData;
            }
          }
        }
      })
      
      if (deleteSessionTypesData !== '') {
        var SessionOptionsSessionTypesResult = await directus.getItems('session_options_session_types', {
          fields: '*',
          filter: {
            session_options_id: { eq: sessionOptionsData.id },
            session_types_id: { in: deleteSessionTypesData },
          }
        });

        let deleteSessionTypesInput = [];

        SessionOptionsSessionTypesResult.data.map(data => {
          deleteSessionTypesInput = [...deleteSessionTypesInput, data.id];
        })
        
        try {
          await directus.deleteItems('session_options_session_types', deleteSessionTypesInput);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

    }
    else if (mode === 'add') 
    {

      let createAwardsData = [];
      let createCustomersData = [];
      let createSessionTypesData = [];

      //================================== Add Session Options Awards Relation ===================================//
      if(awardsFilterChosenRef.current.length > 0) {
        awardsFilterChosenRef.current.map(data => {
          createAwardsData = [...createAwardsData, {"awards_id": {"id":data.id}, status: 'published'}];
        })
      }

      if(customersFilterChosenRef.current.length > 0) {
        customersFilterChosenRef.current.map(data => {
          createCustomersData = [...createCustomersData, {"customers_id":{"id":data.id}, status: 'published'}];
        })
      }

      if(sessionTypesFilterChosenRef.current.length > 0) {
        sessionTypesFilterChosenRef.current.map(data => {
          createSessionTypesData = [...createSessionTypesData, { "session_types_id":{"id":data.id}, status: 'published'}];  
        })
      }


      try {
        await directus.createItems('session_options', {
          sort:sort?sort:'',
          code:code?code:'',
          name:name?name:'',
          description:question?question:'',
          input_type:inputType?inputType:'',
          criteria:criteria?criteria:'',
          default_value:defaultValue?defaultValue.toLowerCase():'',
          only_if_option_set:onlyIfOptionSet?onlyIfOptionSet:'',
          only_overtime:onlyOvertimeRef.current?onlyOvertimeRef.current:'',
          after_overtime:onlyOvertimeRef.current?afterOvertime?afterOvertime:'':0,
          employee_setting:employeeSettingRef.current?employeeSettingRef.current:'',
          invert_output:invertOutputRef.current?invertOutputRef.current:'',
          only_rostered_days:onlyRosteredDaysRef.current?onlyRosteredDaysRef.current:'',
          hide_if_option_set:hideIfOptionSetChosenRef.current?hideIfOptionSetChosenRef.current:'',
          awards:createAwardsData,
          customers:createCustomersData,
          session_types:createSessionTypesData,
          status:'published'
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

    }else{

      let deleteAwardsData = "";
      //================================== Delete Session Options Awards Relation ===================================//
      sessionOptionsData.awards.map(data => {
        const filterInput = linq.from(awardsFilterChosenRef.current)
          .where(x=>x.id === data.awards_id.id).toArray();

        if(!!sessionOptionsData.awards)
        {
          if (filterInput.length === 0) {
            if (deleteAwardsData === '') {
              deleteAwardsData = data.awards_id.id;
            }
            else {
              deleteAwardsData = data.awards_id.id + ',' + deleteAwardsData;
            }
          }
        }
      })
  
      if (deleteAwardsData !== '') {
        var SessionOptionsAwardsResult = await directus.getItems('session_options_awards', {
          fields: '*',
          filter: {
            session_options_id: { eq: sessionOptionsData.id },
            awards_id: { in: deleteAwardsData },
          }
        });

        let deleteAwardsInput = [];

        SessionOptionsAwardsResult.data.map(data => {
          deleteAwardsInput = [...deleteAwardsInput, data.id];
        })

        try {
          await directus.deleteItems('session_options_awards', deleteAwardsInput);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }


      let deleteCustomersData = "";
      //================================== Delete Session Options Customers Relation ===================================//
      sessionOptionsData.customers.map(data => {
        const filterInput = linq.from(customersFilterChosenRef.current)
          .where(x=>x.id === data.customers_id.id).toArray();

        if(!!sessionOptionsData.customers)
        {
          if (filterInput.length === 0) {
            if (deleteCustomersData === '') {
              deleteCustomersData = data.customers_id.id;
            }
            else {
              deleteCustomersData = data.customers_id.id + ',' + deleteCustomersData;
            }
          }
        }
      })

      if (deleteCustomersData !== '') {
        var SessionOptionsCustomersResult = await directus.getItems('session_options_customers', {
          fields: '*',
          filter: {
            session_options_id: { eq: sessionOptionsData.id },
            customers_id: { in: deleteCustomersData },
          }
        });

        let deleteCustomersInput = [];

        SessionOptionsCustomersResult.data.map(data => {
          deleteCustomersInput = [...deleteCustomersInput, data.id];
        })

        try {
          await directus.deleteItems('session_options_customers', deleteCustomersInput);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      let deleteSessionTypesData = "";
      //================================== Delete Session Options Session Types Relation ===================================//
      sessionOptionsData.session_types.map(data => {
        const filterInput = linq.from(sessionTypesFilterChosenRef.current)
          .where(x=>x.id === data.session_types_id.id).toArray();

        if(!!sessionOptionsData.session_types)
        {
          if (filterInput.length === 0) {
            if (deleteSessionTypesData === '') {
              deleteSessionTypesData = data.session_types_id.id;
            }
            else {
              deleteSessionTypesData = data.session_types_id.id + ',' + deleteSessionTypesData;
            }
          }
        }
      })
      
      if (deleteSessionTypesData !== '') {
        var SessionOptionsSessionTypesResult = await directus.getItems('session_options_session_types', {
          fields: '*',
          filter: {
            session_options_id: { eq: sessionOptionsData.id },
            session_types_id: { in: deleteSessionTypesData },
          }
        });

        let deleteSessionTypesInput = [];

        SessionOptionsSessionTypesResult.data.map(data => {
          deleteSessionTypesInput = [...deleteSessionTypesInput, data.id];
        })
        
        try {
          await directus.deleteItems('session_options_session_types', deleteSessionTypesInput);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      
      try {
        await directus.deleteItem('session_options', sessionOptionsData.id);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    loadSessionOptions();
    setLoading(false);
    handleClose();
  }

  return[
    //states
    {
      open, title, sessionOptions,
      mode,  loading, disable,
      pageNumberRef, totalPage, page,
      pageNumber, text, localconfiguration,
      sort, code, name, question, inputType, criteria,
      defaultValue, afterOvertime, onlyIfOptionSet,
      onlyOvertimeRef, employeeSettingRef, invertOutputRef,
      awardsFilterChosenRef, awards, customersFilterChosenRef, customers,
      sessionTypesFilterChosenRef, sessionTypes, onlyRosteredDaysRef,
      hideIfOptionSet, hideIfOptionSetChosenRef, loadingProcessRef
    },
    //function
    {
      setAwardsFilterChosen, setCustomersFilterChosen, setSessionTypesFilterChosen,
      handleClickOpen, handleClose, SaveSessionOptions, setHideIfOptionSetChosen,
      handleChange, handleChangeInput, pageChange
    }
  ];
}

export default SessionOptionViewModel;