import React, { useState, useEffect } from 'react';
import {
  CustomerController, 
  controlLoadActivities,
} from '../../Controller/Settings/Activities.js';
import directus from '../../services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import useStateRef from "react-usestateref";
import linq from "linq";
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../views/Components/ErrorMessage';

let page = 1;

export const ActivitiesViewModel = () => {
  /* eslint-disable no-unused-vars*/
  const [initialized, setInitialized] = useState(false);
  const [activities, setActivities] = useState(null);

  const [activity, setActivity] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [customersFilterChosen, setCustomersFilterChosen, customersFilterChosenRef] = useStateRef([]);

  const [searchActivities, setSearchActivities] = useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingProcess, setLoadingProcess, loadingProcessRef] = useStateRef(false);

  const [title, setTitle] = React.useState(null);
  const [totalPage,setTotalPage]= useState('');
  const [pageNumber, setPageNumber,pageNumberRef] = useStateRef(localStorage.getItem("activities_page")?localStorage.getItem("activities_page"):50);

  const [mode, setMode] = useState('');
  const [name, setName] = React.useState('');
  const [code, setCode] = React.useState('');

  const [ActivityData, setActivityData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  /* eslint-enable no-unused-vars*/

  let disable = name === ''|| code === '';
  let localconfiguration = JSON.parse(window.localStorage.getItem('configuration'));

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadActivities();
    }

    const searchActivity = (eventData) => {
      setLoadingProcess(true);
      page = 1;
      setSearchActivities(eventData.text);
      loadActivities(eventData.text);
    }

    const listener = EventEmitter.addListener('searchActivity', searchActivity);

    return () => {
      listener.remove();
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadActivities = async (data) => {
    try
    {
      var ActivitiesResults = await controlLoadActivities(data,page);
      
      // sort code for activities
      ActivitiesResults.data.map((result)=>{
        return result.customers.sort((a, b) => a.customers_id.code > b.customers_id.code ? 1 : -1);
      })    

      setActivities(ActivitiesResults.data);
      setTotalPage(ActivitiesResults.meta.filter_count);
      loadCustomers();
    }
    catch(e)
    {
      enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
    }
  };

  const loadCustomers = async () => {
    try
    {
      var CustomersResults = await CustomerController();
      setCustomers(CustomersResults.data);
    }
    catch(e)
    {
      enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
    }
    setLoadingProcess(false);
  };

  const pageChange = (value) => {
    setLoadingProcess(true);
    page = page + value;
    loadActivities(searchActivities);
  }

  const handleChange = (event) => {
    setLoadingProcess(true);
    setPageNumber(event.target.value);
    localStorage.setItem("activities_page", JSON.parse(event.target.value));
    page=1;
    loadActivities(searchActivities);
  };

  /* eslint-disable array-callback-return*/
  const handleClickOpen = (Activity, mode) => {
    setOpen(true);
    
    if(mode === 'edit')
    {
      setMode('edit');
      setActivityData(Activity);
      setName(Activity.name);
      setCode(Activity.code);
      if(Activity.customers.length > 0)
      {
        let result = [];
        Activity.customers.map(data => {
          result = [...result, data.customers_id];
        })
        setCustomersFilterChosen(result);
      }
      else
      {
        setCustomersFilterChosen([]);
      }
      setActivity(Activity);
      setTitle('Edit '+Activity.name);
    }
    else if(mode === 'add')
    {
      setMode('add');
      setActivityData('');
      setName('');
      setCode('');
      setActivity('');
      setTitle('Add Activity');
      setCustomersFilterChosen([]);
    }
    else
    {
      setMode('delete');
      setActivityData(Activity);
      setName(Activity.name);
      setCode(Activity.code);
      setActivity(Activity);
      setTitle('Delete '+Activity.name);
    }

  };

  const handleClose = () => {
    setOpen(false);
  };

  const SaveActivity = async () => {
    setLoading(true);
    setLoadingProcess(true);

    let createData = [];
    let merge = '';

    if(mode === 'edit')
    {
      customersFilterChosenRef.current.map(data => {
        const filterInput = linq.from(activity.customers).where(x=>x.customers_id.id===data.id).toArray();
          if(filterInput.length === 0)
          {
            merge = [...merge, data.id];
            createData = [...createData, {id:uuidv4(), activities_id:activity.id, customers_id:data.id, status:'published'}];
          }
      })
   
      if(createData.length > 0)
      {
        try
        {
          await directus.createItems('activities_customers', createData);
        }
        catch(e)
        {
          enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
        }
      }

       // DELETE ===============================================================================================================
      let deleteData = '';

      activity.customers.map(customer => {
        const filterInput = linq.from(customersFilterChosenRef.current)
          .where(x=>x.id===customer.customers_id.id).toArray();
        if(filterInput.length === 0)
        {
          merge = [...merge, customer.customers_id.id];
          if(deleteData === '')
          {
            deleteData = customer.customers_id.id;
          }
          else
          {
            deleteData = customer.customers_id.id + ',' + deleteData;
          }
        }
      })

      let deleteInput = [];
      var CustomerEmployeeResult;

      if(deleteData !== '')
      {
        try
        {
          CustomerEmployeeResult = await directus.getItems('activities_customers', {
            fields: '*',
            filter: {
              activities_id: { eq: activity.id },
              customers_id: { in: deleteData }
            }
          });
        }
        catch(e)
        {
          enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
        }

        CustomerEmployeeResult.data.map(data=>{
          deleteInput = [...deleteInput, data.id]
        })

        try
        {
          await directus.deleteItems('activities_customers', deleteInput);
        }
        catch(e)
        {
          enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
        }
      }

      try
      {
        await directus.updateItem('activities', ActivityData.id, {name: name,code:code});
      }
      catch(e)
      {
        enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
      }
    }
    else if(mode === 'add')
    {
      let customerData = [];

      customersFilterChosenRef.current.map(customer => {
        customerData = [...customerData, {"customers_id": { "id": customer.id}}];
      })
      
      try
      {
        await directus.createItems('activities',{
          code: code,
          name: name,
          status: 'published',
          customers: customerData
        });
      }
      catch(e)
      {
        enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
      }
    }
    else
    {
      try
      {
        await directus.deleteItem('activities', ActivityData.id);  
      }
      catch(e)
      {
        enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
      }
    }

    loadActivities(searchActivities?searchActivities:'');
    setLoading(false);
    handleClose();
  }

  const handleChangeInput = (e) => {
    const {name,value} = e.target;
    if(name==='name')
    {
      setName(value);
    }
    else
    {
      setCode(value);
    }
  }

  const handleChecked = (event) => {
    let result = [];
    if(mode ==='add')
    {
      if(event.target.checked === true)
      {  
        setCustomersFilterChosen(customers);
      }
      else
      {
        setCustomersFilterChosen([]);
      }
    }
    else
    {
      if(event.target.checked === true)
      {  
        setCustomersFilterChosen(customers);
      }
      else if(event.target.checked === false)
      {
        if(activity.customers.length > 0)
        {
          activity.customers.map(data => {
            result = [...result, data.customers_id];
          })
          setCustomersFilterChosen(result);
        }
        else
        {
          setCustomersFilterChosen([]);
        }
      }
      else
      {
        setCustomersFilterChosen([]);
      }
    }
  };  
 /* eslint-enable array-callback-return*/

 return[
    //states
    { 
      disable, name, title, activities, 
      localconfiguration, open, mode, code, 
      customers, customersFilterChosenRef, loading, pageNumber,
      pageNumberRef, totalPage, page, loadingProcessRef
    }, 
    //setStates
    { 
      setCustomersFilterChosen, SaveActivity 
    },
    //function
    { 
      pageChange,handleClickOpen, handleClose, handleChangeInput, 
      handleChecked,handleChange
    },
  ];
}

export default ActivitiesViewModel;