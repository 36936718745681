export default {
  h1: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: 35,
    letterSpacing: '-0.24px'
  },
  h2: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: 29,
    letterSpacing: '-0.24px'
  },
  h3: {
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    fontSize: 24,
    letterSpacing: '-0.06px'
  },
  h4: {
    fontFamily: 'Manrope',
    fontWeight: '400',
    fontSize: 18,
    letterSpacing: '-0.06px'
  },
  h5: {
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    fontSize: 16,
    letterSpacing: '-0.05px',
  },
  h6: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: '-0.05px',
    colors:' #333942'
  },
  h7: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: '-0.05px',
    colors:' #333942'
  },
  body1: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: 16
  },
  subtitle1: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    colors:' #333942'
  },
  subtitle2: {
    fontFamily: 'Manrope',
    fontWeight: 900,
    fontSize: 16,
  },
  overline: {
    fontWeight: 500
  },
  body2: {
    fontFamily: 'Manrope',
    fontWeight: 400,
    fontSize: 16,
    lineHeight:'21.86px',
  }
};
