import directus from 'src/services/directus';
import { AdminPermission } from 'src/utils/Permission';
import { DirectusEmployee } from 'src/views/Components/LocalstorageData.js';
import linq from "linq";


export const loadSessions = async (employeeData, status, type) => {  
  const directusEmployeeData = DirectusEmployee();
  let employeesChildIds = [];
  let filterData;

  if(AdminPermission())
  {
    filterData = {
      'employee.status': { eq: 'published' }
    }
  }
  else
  {
    var EmployeeResult = await directus.getItems('employees', {
      fields: '*.*',
      filter: {id: { eq: [directusEmployeeData.id] }},
      limit: 1
    });

    if(EmployeeResult.data[0].leave_approver.length > 0)
    {
      //eslint-disable-next-line array-callback-return
      EmployeeResult.data[0].leave_approver.map((leave,index) => {
        employeesChildIds[index+1] = leave.child;
      })
    }

    filterData = {
      'employee.id': { in: employeesChildIds },
      'employee.status': { eq: 'published' }
    }
  }

  if(status !== 'all' && status !== 'approved')
  {
    filterData = {
      ...filterData,
      leave_status: { eq: status }
    }
  }
  else if(status === 'pending')
  {
    filterData = {
      ...filterData,
      leave_status: { in: ['pending'] }
    }
  }
  else if(status === 'approved')
  {
    filterData = {
      ...filterData,
      leave_status: { in: ['approved'] }
    }
  }
  else
  {
    filterData = {
      ...filterData,
      leave_status: { nempty: true }
    }
  }

  filterData = {
    ...filterData,
    'session_type.fulltime_only': { nempty: true },
    'session_date' : { gte: 'now' },
    'status': { in: ['pending','rejected','approved'] },
  }

  if(employeesChildIds.length > 0 || AdminPermission())
  {    
    var SessionsResult = await directus.getItems('sessions', {
      fields: 'id,leave_status,session_type.*.*,attachments.*,employee.*.*,break_end_time,break_start_time,break_time,duration,options,resources,session_date,session_end_time,session_start_time,end_time,start_time,status,type',
      // fields:'*.*.*',
      filter: filterData,
      sort: 'session_date',
      limit: -1,
    });

    let filterSession;
    if(SessionsResult.data.length > 0)
    {
      filterSession = linq.from(SessionsResult.data)
        .orderByDescending(x=>x.leave_status==='pending')
        .thenByDescending(x=>x.leave_status==='approved')
        .thenByDescending(x=>x.leave_status==='approved_hrd')
        .thenByDescending(x=>x.leave_status==='rejected').toArray();
        
      if(type === 2)
      {
        let employeesId=[];
        var employeeFiltered = [];
  
        //eslint-disable-next-line array-callback-return
        filterSession.map((data,index)=>{
          if(data.user !== null)
          {
            employeesId[index] = data.employee;
          }
        })
  
        employeesId.forEach(function(item){
          var i = employeeFiltered.findIndex(x => x.user.id === item.user.id);
          if(i <= -1){
            employeeFiltered.push({...item});
          }
        });
  
        let filterEmployee = linq.from(employeeFiltered)
          .orderBy(x=>x.code).toArray();
        return [filterSession, filterEmployee];
      }
      
      return filterSession;
    }
    else
    {
      if(type === 2)
      {
        return [SessionsResult.data, SessionsResult.data];
      }
      return SessionsResult.data;
    }
  }
  else
  {
    return [];
  }
};