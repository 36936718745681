import React from 'react';
import {  
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import directus from '../../services/directus';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage';
import { SubmitButton, CancelButton, TextFieldCustom, TextFieldNotes } from './style.js';
import { DirectusEmployee } from 'src/views/Components/LocalstorageData.js';

let calculate;
const directusEmployeeData = DirectusEmployee();


export default function ApproveRejectButton(props) {
  const [open, setOpen] = React.useState(false);
  const [note, setNote] = React.useState('');
  const [weekMinutes, setWeekMinutes] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  var configuration = JSON.parse(window.localStorage.getItem('configuration')).full_week_minutes; 

  const handleClickOpen = async() => {
    if(props.enableTimesheetBankHours){
      setWeekMinutes(configuration);
      calculate = (props.totalDuration - configuration);
    }
    setDuration(time_convert_Duration(props.timesheets.banked_minutes));
    setDurationClaim(time_convert_Duration(props.timesheets.claim_banked_minutes));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = e => {
    const {name, value} = e.target;
    if(name === 'notes')
    {
      setNote(value);
    }
    else if(name === 'duration')
    {
      setDuration(value);
    }
    else if(name === 'duration_claim')
    {
      setDurationClaim(value);
    }
  }

  const submitApprovalButton = async() => {
    props.changeButtonLoading(true);
    props.setLoading(true);
    let timesheetId = window.timesheetId;

    let dataSubmit;

    if(duration !== '' || duration !== "0" || duration !== null)
    {
      dataSubmit = {
        status: 'awaiting_approval',
        submission_notes: note,
        banked_minutes: parseTimeSpan(duration)
      }
    }
    else
    {
      dataSubmit = {
        status: 'awaiting_approval',
        submission_notes: note,
      }
    }

    if(durationClaim !== '' || durationClaim !== "0" || durationClaim !== null)
    {
      dataSubmit = {...dataSubmit,claim_banked_minutes: parseTimeSpan(durationClaim)};
    }

    try
    {
      await directus.updateItem('timesheets', timesheetId, dataSubmit);
    }
    catch(e)
    {
      enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
    }  
    
    
    props.loadTimesheets();
    props.changeButtonLoading(false);
  }

  const editSubmissionButton = async() => {
    props.changeButtonLoading(true);
    props.setLoading(true);
    let timesheetId = window.timesheetId;

    try
    {
      var getSessionsData = await directus.getItems('sessions', {
        fields: '*',
        filter: {
          timesheet: window.timesheetId,
          status: 'rejected'
        },
      });
    }
    catch(e)
    {
      enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
    }

    if(getSessionsData.data.length > 0)
    {
      let sessionData = [];
      //eslint-disable-next-line array-callback-return
      getSessionsData.data.map((data)=>{
        sessionData.push({id: data.id, status: 'pending'});
      })

      try
      {
        await directus.updateItems('sessions', sessionData);
      }
      catch(e)
      {
        enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
      }  
    }
    try
    {
      await directus.updateItem('timesheets', timesheetId, {
      status: 'pending'
      });
    }
    catch(e)
    {
      enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
    }  

    props.loadTimesheets();
    props.changeButtonLoading(false);
  }

  const [duration, setDuration] = React.useState(time_convert_Duration(props.timesheets.banked_minutes));
  const [durationClaim, setDurationClaim] = React.useState(time_convert_Duration(props.timesheets.claim_banked_minutes));

  const checkTimeValid = (data) => {
    if(moment(data).isValid())
    {
      return true;
    }
    else
    {
      setDuration('');
    }
  }

  const checkClaimTimeValid = (data) => {
    if(moment(data).isValid())
    {
      return true;
    }
    else
    {
      setDurationClaim('');
    }
  }


  function time_convert_claim()
  {
    if(durationClaim !== '' || durationClaim !== "0" || durationClaim !== null)
    {
      let parts = durationClaim.split("h");
      let parts2 = durationClaim.split("m");
      if(parts.length > 1 || parts2.length > 1)
      {
        if(parts.length === 1)
        {
          if(checkClaimTimeValid(parseInt(parts[0].slice(0, -1), 10)))
          {
            setDurationClaim(parseInt(parts[0].slice(0, -1), 10) + "m");
          }
        }
        else
        {
          if(checkClaimTimeValid((parseInt(parts[0], 10) * 60 + (parseInt(parts[1].slice(0, -1)|0, 10)))))
          {
            setDurationClaim((parseInt(parts[0], 10) + "h ") + (parseInt(parts[1].slice(0, -1)|0, 10)>60?0 +"m" :parseInt(parts[1].slice(0, -1)|0, 10) +"m"));
          }
        }
      }
      else
      {
        var decimalTimeString = durationClaim;
        var n = new Date(0,0);
        n.setMinutes(+decimalTimeString * 60);
        if(checkClaimTimeValid(n))
        {
          setDurationClaim((moment(n).hour() + "h ") + moment(n).minute() + "m");
        }
      }
    }
  }

  function time_convert()
  {
    if(duration !== '' || duration !== "0" || duration !== null)
    {
      let parts = duration.split("h");
      let parts2 = duration.split("m");
      if(parts.length > 1 || parts2.length > 1)
      {
        if(parts.length === 1)
        {
          if(checkTimeValid(parseInt(parts[0].slice(0, -1), 10)))
          {
            setDuration(parseInt(parts[0].slice(0, -1), 10) + "m");
          }
        }
        else
        {
          if(checkTimeValid((parseInt(parts[0], 10) * 60 + (parseInt(parts[1].slice(0, -1)|0, 10)))))
          {
            setDuration((parseInt(parts[0], 10) + "h ") + (parseInt(parts[1].slice(0, -1)|0, 10)>60?0 +"m" :parseInt(parts[1].slice(0, -1)|0, 10) +"m"));
          }
        }
      }
      else
      {
        var decimalTimeString = duration;
        var n = new Date(0,0);
        n.setMinutes(+decimalTimeString * 60);
        if(checkTimeValid(n))
        {
          setDuration((moment(n).hour() + "h ") + moment(n).minute() + "m");
        }
      }
    }
  }

  function time_convert_Duration(num)
  { 
    var hours = Math.floor(num / 60);  
    var minutes = num % 60;

    return hours + "h " + minutes +"m";         
  }

  function parseTimeSpan(timeString) {
    let parts = timeString.split("h");
    let parts2 = timeString.split("m");
    if(parts.length > 1 || parts2.length > 1)
    {
      if(parts.length === 1)
      {
        return (parseInt(parts[0].slice(0, -1), 10))
      }
      else
      {
        return (parseInt(parts[0], 10) * 60) + (parseInt(parts[1].slice(0, -1)|0, 10))
      }
    }
    else
    {
      var decimalTimeString = timeString;
      var n = new Date(0,0);
      n.setMinutes(+decimalTimeString * 60); 
      return ((moment(n).hour() * 60) + moment(n).minute());
    }
  }

  return (
    <div>
      {props.timesheets.status === 'pending'?
        <div>
          <SubmitButton 
            id="submit_for_approval_btn"
            size="small" 
            onClick={handleClickOpen}
          >
            <Typography variant="h6">Submit for Approval</Typography> 
          </SubmitButton>
          <Dialog id="submit_for_approval_dialog" isopen={`${open}`} open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
            <DialogContent>
              <DialogContentText>
                Timesheet submission details
              </DialogContentText>
              <Typography variant="h6">Notes</Typography>
              <TextFieldNotes
                size='small'
                id="reject-note"
                name="notes"
                placeholder='(Optional) Leave a note'
                multiline
                fullWidth={true}
                minRows={4}
                maxRows={4}
                value={note}
                onChange={handleInputChange}
                variant="outlined"
              />

              {props.enableTimesheetBankHours?
                <div>
                  <br/>
                  <TextFieldCustom
                    variant="outlined"
                    label={directusEmployeeData.rdo_hours_enable?"RDO Time Accrued":"Bank Extra Hours"+(calculate>=0?"(max: " + time_convert_Duration(calculate) +")":'')}
                    name="duration"
                    placeholder="1h 30m"
                    sx={{width: '45%' }}
                    value={duration}
                    onChange={handleInputChange}
                    onBlur={(e)=>time_convert(e)}
                    size="small"
                  />
                  <br/>
                  <br/>
                  <TextFieldCustom
                    variant="outlined"
                    label={directusEmployeeData.rdo_hours_enable?"RDO Time Taken":"Claim Banked Hours"}
                    name="duration_claim"
                    placeholder="1h 30m"
                    sx={{width: '45%' }}
                    value={durationClaim}
                    onChange={handleInputChange}
                    onBlur={(e)=>time_convert_claim(e)}
                    size="small"
                  />
                </div>
              :''}
            </DialogContent>
            <DialogActions>
              <CancelButton
                id="cancel_approval" 
                size="small" 
                variant="contained"
                onClick={handleClose}
              >
              <Typography variant="h6"> Cancel</Typography> 
              </CancelButton>
              <SubmitButton 
                id="submit_approval"  
                size="small" 
                variant="contained"
                onClick={submitApprovalButton}
              >
                <Typography variant="h6">Submit</Typography> 
              </SubmitButton>
            </DialogActions>
          </Dialog>
        </div>
      :''}

      {props.timesheets.status === 'rejected' || props.timesheets.status === 'awaiting_approval'?
        <SubmitButton 
          id="edit_submission_btn"
          size="small" 
          variant="contained"
          onClick={editSubmissionButton}
        >
         <Typography variant="h6">Edit Submission</Typography> 
        </SubmitButton>
      :''}
    </div>
  );
}
