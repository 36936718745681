import React, { useState, useEffect } from 'react';
import { RegionsController } from '../../Controller/Settings/ReuseController.js';
import { PublicHolidaysController } from '../../Controller/Settings/PublicHolidays.js';
import directus from '../../services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import moment from 'moment';
import useStateRef from "react-usestateref";
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../views/Components/ErrorMessage';

let page = 1;

export const PublicHolidaysViewModel = () => {
  /* eslint-disable no-unused-vars*/
  const [initialized, setInitialized] = useState(false);
  const [Holidays, setHolidays] = useState(null);
  const [searchHol, setSearchHol] = useState('');
  
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [mode, setMode] = useState('');

  const [loadingProcess, setLoadingProcess, loadingProcessRef] = useStateRef(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = React.useState('');

  const [selectedDate, handleDateChange] = useState(new Date());
  const [holidayData, setHolidayData] = useState(null);
  const [description, setDescription] = React.useState('');

  const [totalPage, setTotalPage, totalPageRef] = useStateRef("");
  const [pageNumber, setPageNumber, pageNumberRef] = useStateRef(localStorage.getItem("publicholiday_page")?localStorage.getItem("publicholiday_page"):50);
  const [regions, setRegions] = React.useState('');

  const [regionsData, setRegionsData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  
  /* eslint-enable no-unused-vars*/
  let localconfiguration = JSON.parse(window.localStorage.getItem('configuration'));

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadRegions();
      loadPublicHolidays();
    }

    const searchHoliday = (eventData) => {
      setLoadingProcess(true);
      page = 1;
      setSearchHol(eventData.text);
      loadPublicHolidays(eventData.text);
    }

    const listener = EventEmitter.addListener('searchHoliday', searchHoliday);

    return () => {
      listener.remove();
    }
  });
  
  const loadRegions = async () => {
    try 
    {
      var public_holiday_regions = await RegionsController();
      setRegionsData (public_holiday_regions.data);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e.code), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  };

  /* eslint-enable react-hooks/exhaustive-deps*/

  let disable = name === '' || selectedDate === null || selectedDate === 'Invalid Date';

  const loadPublicHolidays = async (data) => {
    var HolidaysResults = await PublicHolidaysController(data,page);
    setTotalPage(HolidaysResults.meta.filter_count);
    setHolidays(HolidaysResults.data);
    setLoadingProcess(false);
  };

  const pageChange = (value) => {
    setLoadingProcess(true);
    page = page + value;
    loadPublicHolidays(searchHol);
  }

  const handleChange = (event) => {
    setLoadingProcess(true);
    page = 1;
    setPageNumber(event.target.value);
    localStorage.setItem("publicholiday_page", JSON.parse(event.target.value));
    loadPublicHolidays(searchHol);
  };

  const handleClickOpen = (holiday, mode) => {
    setOpen(true);
    if (mode === 'edit') {
      handleDateChange(holiday.date)
      setName(holiday.name);
      setDescription(holiday.description)
      setHolidayData(holiday);
      setRegions(holiday.region)
      setMode('edit')
      setTitle('Edit Public Holiday');
      setText('');
    }
    else if (mode === 'add') {
      handleDateChange(new Date())
      setName('');
      setDescription('')
      setRegions('')
      setMode('add');
      setTitle('Add Public Holiday');
      setText('');
    }
    else {
      setHolidayData(holiday);
      setMode('delete');
      setTitle('Delete Public Holiday');
      setText('Are you Sure?');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeInput = (e, type) => {
    if (type === "region") {
      setRegions(e)
    } else {
      const { name, value } = e.target;
      if (name === 'name') {
        setName(value);
      }
      else {
        setDescription(value);
      }
    }
  }

  const SaveHoliday = async () => {
    setLoading(true);
    if (mode === 'edit') {
      try {
        await directus.updateItem('public_holidays', holidayData.id, {
          name: name,
          description: description ? description : '',
          date: moment(selectedDate).format('YYYY-MM-DD'),
          region: regions ? regions.id : null
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }
    else if (mode === 'add') {
      try {
        await directus.createItem('public_holidays', {
          name: name,
          description: description ? description : '',
          date: moment(selectedDate).format('YYYY-MM-DD'),
          region: regions ? regions.id : null,
          status: 'published'
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }
    else {
      try {
        await directus.deleteItem('public_holidays', holidayData.id);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }
    loadPublicHolidays();
    setLoading(false);
    handleClose();
  }

  return[
    //states
    { 
      title, text, disable, open,
      Holidays, name, regions, description,
      localconfiguration, mode, regionsData, loading,
      selectedDate, pageNumber, pageNumberRef, totalPage, 
      page, loadingProcessRef
    },
    //function
    { 
      SaveHoliday, handleClickOpen, handleClose, handleDateChange,
      handleChangeInput, pageChange, handleChange,
    },
  ];
}

export default PublicHolidaysViewModel;