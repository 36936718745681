import React, {useState} from 'react';
import { 
  Grid,
  Typography
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { 
  useStylesEditSession, 
} from '../style';

const AddEquipment = (props) => {
  const classes = useStylesEditSession();
  const [newValue, setNewValue] = useState([]);
  
  function handleChange(newData){
    setNewValue(newData);
  }

  const handleSubmit = e => {
    e.preventDefault();
    if(newValue === undefined)
    {
      handleChange(props.addEquipment(null));
    }
    else
    {
      handleChange(props.addEquipment(newValue));
    }
    
  }

  return(
    <Grid   
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={0}
    >
      <Grid item xs={10}>
        <Typography className={classes.fontstyle5}>Equipment</Typography>
      </Grid>
      <Grid item xs={2}>
        <AddCircleIcon id="iconButton" className="add_material_iconButton" color="action" style={{ fontSize: 20,float:'right' }} onClick={handleSubmit}/>
      </Grid>
    </Grid>
  );
};

export default AddEquipment;
