import { DirectusEmployee } from 'src/views/Components/LocalstorageData.js';

const directusEmployeeData = DirectusEmployee();

const user = JSON.parse(window.localStorage.getItem('directus_user'));

export function AdminPermission() {
  if(user)
  {
    return (user.role.name === 'Administrator' || user.role.name === 'Admin' || user.role.name === 'Company Admin');
  }
  else
  {
    return false;
  }
}

export function SupervisorPermission() {
  if(user)
  {
    return (user.role.name === 'Supervisor' || AdminPermission());
  }
  else
  {
    return false;
  }
}

export function CustomerEmployeePermission() {
  if(directusEmployeeData)
  {

    if(directusEmployeeData.customers && directusEmployeeData.customers.length > 0)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
}

export function EmployeeLeavePermission() {
  if(directusEmployeeData)
  {
    if(directusEmployeeData.leave_approver && directusEmployeeData.leave_approver.length > 0)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
}

export function EmployeeEmployeePermission() {
  if(directusEmployeeData)
  {
    if(directusEmployeeData.related && directusEmployeeData.related.length > 0)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
}
