import React from 'react';
import {
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  Typography,
  Grid,
  Checkbox,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  useStylesEmployees,
  AddButton,
  SubmitButton,
  CancelButton,
  CustomTooltip,
  RosterButton,
  SyncButton,
  pageOptions,
  CustomGridApproval,
  CustomAccordion,
  CustomAccordionSummary,
  GridCustom,
  TextFieldCustom,
  TextFieldCustom2,
  AutoCompleteCustom,
  CustomSwitch,
  FormControlCustom,
  SendResetPWButton,
} from './style.js';
import { useStyles, CustomTableCellTime, CustomTableCell } from './styleemployee.js';
import RestoreIcon from '@mui/icons-material/Restore';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DeletedEmployeesViewModel } from "../../ViewModel/Settings/DeletedEmployeesViewModel.js";
import IconButton from "@mui/material/IconButton";
import Skeleton from '@mui/material/Skeleton';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const companyName = JSON.parse(localStorage.getItem('company'));

export default function DeletedEmployees(props) {
  /* eslint-disable no-unused-vars*/
  const [
   //states
   {
    employees, anchorEl, anchorE2, integration,
    filter, localconfiguration, configuration, check,
    open, title, mode, userLoading, employeeLoading,
    firstNameRef, lastNameRef, codeRef,
    emailRef, mobileNumberRef, timezoneRef, timezoneFilterChosenRef,
    regions, regionsData, customers, employeesFilter,
    employeesFilterChosenRef, managementFeeStatusRef, awardsData, awardsEmployee,
    customerAssignedToRef, role, roleFilterChosenRef, customersFilterChosenRef,
    employeesLeavesChosenRef, password, emailNotificationStatusRef,
    rdoHoursEnableRef, loading, disable, openRoster,
    employee, rowsRef, loadingRoster, disableRoster,
    pageNumberRef, totalPage, page, loadingSync,
    employmentTypeRef, employmentTypeFilterChosenRef, loadingProcessRef
  },
  //setStates
  {
    setCustomersFilterChosen, setEmployeesFilterChosen, setEmployeesLeavesChosen,
    setRoleFilterChosen, setTimezoneFilterChosen, setEmploymentTypeFilterChosen,
  },
  //function
  {
    SaveEmployee, handleCloseRoster, onToggleEditMode,
    handleStatusClick, handleStatusClose, handleFilterClick, handleClickOpen,
    handleFilterClose, tickHandleChange, handleClickOpenRoster, handleClose,
    onChange, handleClickShowPassword, handleMouseDownPassword, SaveRoster,
    handleChange, handleChangeInput, diffTime, sendEmail,
    filterStatus, pageChange, onRevert, loadSyncEmployees
  }
  ] = DeletedEmployeesViewModel();

  const classes = useStylesEmployees();
  const classes2 = useStyles();
  var company = JSON.parse(window.localStorage.getItem('company'));
  /* eslint-enable array-callback-return*/

  return (
    <div >
      {employees ?
        <span>
          <Grid container
            spacing={0}
            style={{ marginBottom: '5px' }}
            alignItems="center"
          >
            <Grid item xs={1} md={1} lg={1}>
              <Typography variant="h5" style={{ paddingLeft: '20px' }}>Code</Typography>
            </Grid>
            <Grid item xs={2} md={2} lg={2}>
              <Typography variant="h5" style={{ paddingLeft: '15px' }}>Name</Typography>
            </Grid>
            <Grid item xs={2} md={2} lg={3} style={{ marginRight: '-17px' }}>
              <Typography variant="h5">Email</Typography>
            </Grid>
            <Grid item xs={1} md={1} lg={1} style={{ marginRight: '-6px' }}>
              <Typography variant="h5">Phone</Typography>
            </Grid>
            <Grid item xs={1} md={1} lg={1} style={{ marginRight: '-7px' }}>
              <Typography variant="h5">Role</Typography>
            </Grid>
            <Grid item xs={2} md={2} lg={2}>
              <Typography variant="h5">Employment Type</Typography>
            </Grid>
            <Grid item xs={2} md={2} lg={2}>  
              <Typography variant="h5"></Typography>
            </Grid>
          </Grid>

          {/* eslint-disable-next-line array-callback-return */}

          {loadingProcessRef.current ?
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
            </Grid>
          :
          employees.map((employee, index) => {
            if (employee.user) {
              return (
                <CustomAccordion key={index}>
                  <CustomAccordionSummary
                    id={`employee_list_${index}`}
                    expandIcon={<ExpandMoreIcon />}
                    style={{ userSelect: 'auto', alignItems: 'flex-start' }}
                  >
                    <Grid container onFocus={(event) => event.stopPropagation()}>
                      <GridCustom item xs={1} md={1} lg={1} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                        <Typography noWrap variant="h6" id={`employee_code_${index}`}>{employee.code ? employee.code : ''}</Typography>
                      </GridCustom>
                      <GridCustom item xs={2} md={2} lg={2} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                        <Typography noWrap variant="h6" id={`employee_name_${index}`}>{employee.user.first_name ? employee.user.first_name : ''} {employee.user.last_name ? employee.user.last_name : ''}</Typography>
                      </GridCustom>
                      <GridCustom item xs={2} md={2} lg={3}>
                        <Typography noWrap variant="h6" id={`employee_email_${index}`}>{employee.user.email}</Typography>
                      </GridCustom>
                      <GridCustom item xs={1} md={1} lg={1}>
                        <Typography noWrap variant="h6" id={`employee_phone_${index}`}>{employee.mobile_number}</Typography>
                      </GridCustom>
                      <GridCustom item xs={1} md={1} lg={1}>
                        <Typography noWrap variant="h6" id={`employee_phone_${index}`}>{employee.user.role.name}</Typography>
                      </GridCustom>
                      <GridCustom item xs={1} md={1} lg={1}>
                        <Typography noWrap variant="h6" id={`employee_type_${index}`}>{employee.type}</Typography>
                      </GridCustom>
                      <Grid item xs={4} md={4} lg={3} style={{ textAlign: 'right' }}>
                        <IconButton id={`edit_btn_${index}`} aria-label="edit" onClick={(event) => { event.stopPropagation(); handleClickOpen(employee); }} >
                          <RestoreIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CustomAccordionSummary>
                  <AccordionDetails>
                    <Grid container key={index} style={{ paddingBottom: '1px' }}>
                      <Grid item xs={12} style={{ textAlign: 'left' }}>
                        {employee.customers.length > 0 ?
                          <div>
                            <Grid container key={index}>
                              <Grid item xs={2} style={{ maxWidth: '8.333333% !important' }}> <Typography noWrap variant="h6"> Supervisor for customer: &nbsp;</Typography></Grid>
                              <Grid item xs={10}>
                                {employee.customers.map((data, index) => {
                                  if (data.customer) {
                                    return (
                                      <CustomTooltip key={index} title={<Typography variant="h6">{data.customer.name}</Typography>}>
                                        <Chip key={index} variant="outlined" size="small" label={<Typography variant="h6" id={`sp_cus_${index}`}>
                                          {data.customer.code ? data.customer.code : data.customer.name ? data.customer.name : ""}
                                        </Typography>} color="primary" />
                                      </CustomTooltip>
                                    );
                                  }
                                })}
                              </Grid>
                            </Grid>
                          </div>
                          : ''}

                        {employee.related.length > 0 ?
                          <div>
                            <Grid container key={index}>
                              <Grid item xs={2} style={{ maxWidth: '8.333333% !important' }}> <Typography noWrap variant="h6"> Supervisor for employee: &nbsp;</Typography></Grid>
                              <Grid item xs={10}>
                                {/* eslint-disable-next-line array-callback-return */}
                                {employee.related.map((data, relatedIndex) => {
                                  if (data.child !== null) {
                                    return (
                                      company === 'mfm' ?
                                        <React.Fragment key={`wrapperEmp-${index}-${relatedIndex}`}>
                                          <CustomTooltip key={relatedIndex} title={<Typography variant="h6">{data.child.user.first_name + ' ' + data.child.user.last_name}</Typography>}>
                                            <Chip key={relatedIndex} variant="outlined" size="small" label={<Typography variant="h6" id={`sp_emp_${relatedIndex}`}>{data.child.code}</Typography>} color="primary" />
                                          </CustomTooltip>
                                        </React.Fragment>
                                        :
                                        <React.Fragment key={`wrapperEmp-${index}-${relatedIndex}`}>
                                          <CustomTooltip key={relatedIndex} title={<Typography variant="h6">{data.child.code}</Typography>}>
                                            <Chip key={relatedIndex} variant="outlined" size="small" label={<Typography variant="h6" id={`sp_emp_${relatedIndex}`}>{data.child.user.first_name + ' ' + data.child.user.last_name}</Typography>} color="primary" />
                                          </CustomTooltip>
                                        </React.Fragment>

                                    );
                                  }
                                })}
                              </Grid>
                            </Grid>
                          </div>
                          : ''}

                        {employee.leave_approver.length > 0 ?
                          check.enable_leave_supervisor ?
                            <div>
                              <Grid container key={index}>
                                <Grid item xs={2} style={{ maxWidth: '8.333333% !important', paddingRight: '2px' }}>
                                  <CustomTooltip title="Leave supervisor for employee">
                                    <Typography noWrap variant="h6">Leave supervisor for employee:  &nbsp;</Typography>
                                  </CustomTooltip>
                                </Grid>
                                <Grid item xs={10}>
                                  {employee.leave_approver.map((data, index) => {
                                    return (
                                      data.child ?
                                        <CustomTooltip key={index} title={<Typography variant="h6">{data.child.user.first_name} {data.child.user.last_name}</Typography>}>
                                          <Chip key={index} variant="outlined" size="small" label={<Typography variant="h6" id={`lv_sp_emp_${index}`}>{data.child.code}</Typography>} color="primary" />
                                        </CustomTooltip>
                                        : ''
                                    );
                                  })
                                  }

                                </Grid>
                              </Grid>
                            </div>
                            : ''
                          : ''}
                      </Grid>
                      <Grid item xs={12}>
                        {employee.user.last_access_on_mobile === null && employee.user.last_mobile_user_agent === null ?
                          <i style={{ fontSize: '13px' }} id={`last_mobile_${index}`}>last accessed on mobile : - </i>
                          :
                          <i style={{ fontSize: '13px' }}>last accessed on mobile is <span id={`last_mobile_${index}`}>{employee.user.last_access_on_mobile}</span>
                            &nbsp;
                            by
                            &nbsp;
                            <span id={`last_mobile_agent_${index}`}>{employee.user.last_mobile_user_agent}</span>
                          </i>
                        }

                      </Grid>
                      <Grid item xs={12}>
                        {employee.user.last_access_on_desktop === null && employee.user.last_desktop_user_agent === null ?
                          <i style={{ fontSize: '13px' }} id={`last_desktop_${index}`}>last accessed on desktop : - </i>
                          :
                          <i style={{ fontSize: '13px' }}>
                            last accessed on desktop is <span id={`last_desktop_${index}`}>{employee.user.last_access_on_desktop}</span>
                            &nbsp;
                            by
                            &nbsp;
                            <span id={`last_desktop_agent_${index}`}>{employee.user.last_desktop_user_agent}</span>
                          </i>
                        }
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </CustomAccordion>
              );
            }
          })}

          <Dialog
            id="employee_dialog"
            isopen={`${open}`}
            open={open}
            fullWidth={true}
            onClose={handleClose}
          >
            <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="employee_dialog_title" component="div" variant="h6"><h2>{title}</h2></Typography></DialogTitle>
            <DialogContent style={{ marginBottom: '5px' }}>
              <Typography noWrap variant="h6">Are you sure?</Typography>
            </DialogContent>
            <DialogActions>
              {loading ?
                <CircularProgress className={classes.circular} />
                :
                <>
                  <CancelButton
                    id="cancel_btn"
                    size="small"
                    variant="contained"
                    onClick={handleClose}
                  >
                    Cancel
                  </CancelButton>

                  <SubmitButton
                    id="save_btn"
                    size="small"
                    variant="contained"
                    onClick={() => SaveEmployee()}
                  >
                    Save
                  </SubmitButton>
                  <br />
                </>
              }
            </DialogActions>
          </Dialog>

        </span>
        :
        <>
          <Grid container spacing={0}>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1} md={1} lg={1}>
              <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
            </CustomGridApproval>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2} md={2} lg={2}>
              <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
            </CustomGridApproval>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2} md={2} lg={3}>
              <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
            </CustomGridApproval>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1} md={1} lg={1}>
              <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
            </CustomGridApproval>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1} md={1} lg={1}>
              <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
            </CustomGridApproval>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1} md={1} lg={1}>
              <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
            </CustomGridApproval>
            <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={4} md={4} lg={3}>
              <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
            </CustomGridApproval>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
            </Grid>
          </Grid>
        </>
      }

      {employees ?
        <Grid container>
          <Grid
            item xs={6}
            style={{ textAlign: 'left', marginTop: '10px', marginBottom: '10px', fontFamily: 'Manrope' }}
          >
            <span style={{ paddingRight: '20px' }}>
              Rows per page:
            </span>
            <span style={{ paddingRight: '20px' }}>
              <TextField
                id="standard-select-currency"
                select
                value={pageNumberRef.current}
                onChange={handleChange}
              >
                {pageOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </span>

            <span id="page_number" style={{ paddingRight: '20px' }}>
              {(pageNumberRef.current * (page - 1)) + 1}- {(pageNumberRef.current * page) > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
            </span>

            <Button
              id="prev_btn"
              variant="outlined"
              size="small"
              disabled={page === 1}
              className={classes.buttonLeft}
              onClick={() => pageChange(-1)}
            >
              <ArrowBackIosRoundedIcon />
            </Button>

            <Button
              id="next_btn"
              variant="outlined"
              size="small"
              className={classes.buttonRight}
              disabled={page >= Math.ceil(totalPage / pageNumberRef.current)}
              onClick={() => pageChange(1)}
            >
              <ArrowForwardIosRoundedIcon />
            </Button>
          </Grid>
        </Grid>
        : " "}
    </div>
  );
}