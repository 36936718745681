import React, { useState, useEffect } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import directus from '../../services/directus.js';
import { SupervisorPermission, AdminPermission } from 'src/utils/Permission';
import useStateRef from 'react-usestateref';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage.js';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const moment = require('moment-timezone');

const CustomAccordion = withStyles({
  root: {
    borderRadius: '8px !important'
  }
})(Accordion);

const CustomAccordionSummary = withStyles({
  root: {
    minHeight: '30px',
    maxHeight: '30px',
    '&.Mui-expanded': {
      maxHeight: '30px'
    }
  }
})(AccordionSummary);

export default function TimesheetEvent() {
  /* eslint-disable no-unused-vars*/
  const [initialized, setInitialized] = useState(false);
  const [timesheetEvent, setTimesheetEvent, timesheetEventRef] = useStateRef('');
  const { enqueueSnackbar } = useSnackbar();
  let directusUser = JSON.parse(window.localStorage.getItem('directus_user'));

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      firstLoad();
    }
  });

  // TODO: set timesheetEvent state with the data from the timesheet_event data retrieval results in directus according to the timesheet id that is the same as the id of the location pathname or URL. And set approvalLockData with the value from the result of retrieving approvals_lock data in directus according to start_date which is the same as start_time from props
  const firstLoad = async () => {
    let timesheetId = window.location.pathname.split('/').pop();
    try {
      var TimesheetEvent = await directus.getItems('timesheet_event', {
        fields: 'id, type, created_on, owner.id, owner.first_name, owner.last_name',
        filter: {
          'timesheet.id': { eq: timesheetId }
        },
        sort: 'created_on'
      });

      const TimesheetEventData = TimesheetEvent.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
      setTimesheetEvent(TimesheetEventData);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  };

  return (
    <>
      {SupervisorPermission() || AdminPermission() ? (
        <div>
          {timesheetEvent.length > 0 ? (
            <CustomAccordion style={{ margin: '0px 10px 10px 0px' }}>
              <CustomAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography style={{ fontSize: '14px', fontFamily: 'Manrope' }}>Change log</Typography>
              </CustomAccordionSummary>
              <AccordionDetails style={{ display: 'inline-block' }}>
                {timesheetEvent.map((timesheetevent, index) => {
                  if (timesheetevent.owner) {
                    return (
                      <ul key={index}>
                      <Typography style={{ fontSize: '12px', fontFamily: 'Manrope', paddingLeft: '15px' }} key={index}>
                        {directusUser.timezone ? (
                          <li>
                            <i>
                              <span style={{ textTransform: 'capitalize' }}>{timesheetevent.type}</span>
                                &nbsp;by&nbsp;
                                <span style={{ textTransform: 'capitalize' }}>
                                  {timesheetevent.owner.first_name} {timesheetevent.owner.last_name}{' '}
                                </span>
                                on&nbsp;
                                <span style={{ textTransform: 'capitalize' }}>
                                  {moment
                                    .tz(timesheetevent.created_on, directusUser.timezone === 'UTC' ? 'Australia/Brisbane' : directusUser.timezone)
                                    .format('MMMM Do YYYY, hh:mm:ss A')}{' '}
                              </span>
                            </i>  
                          </li>
                        ) : (
                          <li>
                          <i>
                            <span style={{ textTransform: 'capitalize' }}>{timesheetevent.type}</span>
                            &nbsp;by&nbsp;
                            <span style={{ textTransform: 'capitalize' }}>
                              {timesheetevent.owner.first_name} {timesheetevent.owner.last_name}{' '}
                            </span>
                            on&nbsp;
                            <span style={{ textTransform: 'capitalize' }}>
                              {moment(timesheetevent.created_on).format('MMMM Do YYYY, hh:mm:ss A')}{' '}
                            </span>
                          </i>
                          </li>
                        )}
                      </Typography>
                      </ul>
                    );
                  }
                })}
              </AccordionDetails>
            </CustomAccordion>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
}
