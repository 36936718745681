import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import { SupervisorPermission } from 'src/utils/Permission';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',    
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  }
}));

var directusUser, user, company_name, company;

try {
  directusUser = JSON.parse(window.localStorage.getItem('directus_user'));
  company_name= JSON.parse(window.localStorage.getItem('directus_data')).company.name;
  company = company_name.charAt(0).toUpperCase() + company_name.slice(1);
  user = {
    avatar: directusUser.avatar ? directus.config.url + directusUser.avatar.data.asset_url : '',
    jobTitle: directusUser.email,
    role: directusUser.role.name,
    name: (directusUser.first_name || '') + ' ' + (directusUser.last_name || '')
  };
} catch (error) {
  if (
    !window.localStorage.getItem('directus_user') &&
    !window.location.pathname.startsWith('/sign-in') &&
    !window.location.pathname.startsWith('/passwordreset')
  ) {
    window.location.href = `//${window.location.hostname}:${window.location.port}/sign-in`;
  }
}

var checkPermission = SupervisorPermission()?true:false;
var checkPageAccess=window.location.pathname === '/'?true:false;
var checkSessionListPage=window.location.pathname.split('/')[1]  === 'approvals' && window.location.pathname.split('/')[2] === undefined?true:false;

const getApprovalSidebarStatus = () =>  {
  return localStorage.getItem('approvals_sidebar_open_status') ? JSON.parse(localStorage.getItem('approvals_sidebar_open_status')) : {data: 1, status: true};
}

const checkIfReportFilterOpen = () => {
  return localStorage.getItem('report_filter_open_status') ? localStorage.getItem('report_filter_open_status') === 'true' : true;
}

const initialSidebarWidth = () => {
  const sidebarStatus = getApprovalSidebarStatus();
  if(checkPermission) {
    // set sidebar width based on user choice (opens or closes sidebar on Approvals page)
    if(checkPageAccess || checkSessionListPage) {
      return sidebarStatus.status ? '298px' : '0px';
    }
    // set sidebar width based on user choice (opens or closes sidebar on Reporting page)
    else if (window.location.pathname === '/reporting') {
      return checkIfReportFilterOpen() ? '298px' : '0px';
    }
    else {
      return '0px';
    }
  } else {
    return '0px';
  }
}

const DashboardLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [width, setWidth] = useState(initialSidebarWidth());

  useEffect(() => {
    const ReviewButton = (eventData) => {
      if (eventData.text === 'ApprovalsReview') {
        setWidth('0px');
      }
    }

    const contentWidthFunc = (eventData) => {
      if(eventData.text && eventData.text === 4)
      {
        checkIfReportFilterOpen() ? setWidth('298px') : setWidth('0px');
      }
      else if (eventData.text && (eventData.text === 1 || eventData.text === 2 || eventData.text === 3)) {
        const sidebarStatus = getApprovalSidebarStatus();
        sidebarStatus.status ? setWidth('298px') : setWidth('0px');
      }
      else if (window.DashboardGlobal) {
        setWidth('298px');
      }
      else
      {
        setWidth('0px');
      }
    }

    const moveTimesheetLoading = async (eventData) => {
      if (eventData.text === 'startLoading') {
        setWidth('0px');
      }
    }

    const listenerMoveTimesheetLoading = EventEmitter.addListener('moveTimesheets', moveTimesheetLoading);
    const listenercontentWidth = EventEmitter.addListener('contentWidth', contentWidthFunc);
    const listenerReviewButton = EventEmitter.addListener('topBar', ReviewButton);


    return () => {
      listenercontentWidth.remove();
      listenerReviewButton.remove();
      listenerMoveTimesheetLoading.remove();
    }
  }, []);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} user={user} company={company}/>
      <div className={classes.wrapper} style={{paddingTop:'80px',paddingRight:width}}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
            <NavBar
              onMobileClose={() => setMobileNavOpen(false)}
              openMobile={isMobileNavOpen}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
