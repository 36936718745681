import directus from '../../services/directus';

export const RegionsController = async() => {
    var result = await directus.getItems('public_holiday_regions');
    return result;
};

export const ConfigTSController = async () => {
    const result = await directus.getItems('config_ts');
    return result;
};

export const CustomersController = async () => {
  var result = await directus.getItems('customers', {
    filter: { status: { eq: 'published' } },
    fields: '*',
    sort: 'code',
    limit: -1
  });
  return result.data;
};

export const TimezoneController = async () => {
  var result = await directus.getField("directus_users", "timezone");
  return result;
};

export const IntegrationController = async () => {
  var result = await directus.getItems('integration');
  return result;
};