import DoneAllIcon from '@material-ui/icons/DoneAll';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SettingsIcon from '@material-ui/icons/Settings';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import * as permission from 'src/utils/Permission';

export function items()
{

let menu_items = [
  {
    href: '/',
    title: permission.SupervisorPermission()?'Approvals':'Timesheet',
    number: permission.SupervisorPermission()?3:2,
    dividerValue: 1,
    features: {
      search: true,
      ExportFile:true,
      Messages:true,
      Filter:true,
      FilterReporting:false,
      Dashboard:true,
      Addtimesheet:true,
    },
    listStyle: {
      display: 'none',
    }
  },
  {
    href: '/timesheet',
    icon: DateRangeIcon,
    title: 'Timesheet',
    number: 2,
    dividerValue: 1,
    features: {
      search: false,
      ExportFile:false,
      Messages:false,
      FilterReporting:false,
      Filter:false,
      Dashboard:false,
      Addtimesheet:false,
    },
    listStyle: {}
  }
];

if( permission.SupervisorPermission() || permission.CustomerEmployeePermission() || permission.EmployeeEmployeePermission())
{
  menu_items.push(
  {
    href: '/approvals',
    icon: DoneAllIcon,
    title: 'Approvals',
    number: 3,
    dividerValue: 1,
    features: {
      search: true,
      ExportFile:true,
      Messages:true,
      Filter:true,
      FilterReporting:false,
      Dashboard:true,
      Addtimesheet:true,
    },
    listStyle: {}
  },
  ) 
}

if(permission.EmployeeLeavePermission() ||permission.AdminPermission())
{
  
  menu_items.push(
  {
    href: '/leave_requests',
    icon: EventAvailableIcon,
    title: 'Leave Requests',
    number: 5,
    dividerValue: 1,
    features: {
      search: true,
      ExportFile:false,
      Messages:false,
      Filter:false,
      FilterReporting:false,
      Dashboard:false,
      Addtimesheet:false,
    },
    listStyle: {}
  },
  ) 
}

if( permission.AdminPermission() )
{
  menu_items.push(
  {
    href: '/reporting',
    icon: FileDownloadIcon,
    title: 'Reporting',
    number: 6,
    dividerValue: 1,
    features: {
      search: false,
      Filter:false,
      FilterReporting:true, 
      Dashboard:false,
    },
    listStyle: {}
  }
  );

  menu_items.push(
  {
    href: '/settings',
    icon: SettingsIcon,
    title: 'Settings',
    number: 7,
    dividerValue: 0,
    features: {
      search: true,
      ExportFile:false,
      Messages:false,
      Filter:false,
      FilterReporting:false,
      Dashboard:false,
      Addtimesheet:false,
    },
    listStyle: {}
  }
  );
}

return menu_items;
}