import { makeStyles } from '@material-ui/core';
import 
{ 
  withStyles,
  Button,
} from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      height: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  }));

export const CancelButton = withStyles({
    root: {
      borderRadius: '8px',
      backgroundColor: '#e0e0e0de',
      height: '30px',
      width: '120px',
      '&:hover': {
        backgroundColor: '#bdbdbdde',
      },
    },
  })(Button);

  export const ActionButton = withStyles({
    root: {
      borderRadius: '8px',
      backgroundColor: '#2AC940',
      color: '#ffffff',
      height: '30px',
      width: '150px',
      '&:hover': {
        backgroundColor: '#22a033',
      },
    },
  })(Button);