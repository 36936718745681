import React from 'react';
import { 
  List,
  ListItem,
  CircularProgress,
  Button,
  Chip,
  Typography,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { 
  useStylesEmployees, 
  AddButton, 
  pageOptions, 
  SubmitButton,
  CancelButton,
  CustomTooltip,
  CustomGridApproval,
  CustomAccordion,
  CustomAccordionSummary,
  GridCustom,
  TextFieldCustom,
  TextFieldCustom2,
  AutoCompleteCustom,
} from './style.js';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {ActivitiesViewModel } from "../../ViewModel/Settings/ActivitiesViewModel";
import Skeleton from '@mui/material/Skeleton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Activities() {
  /* eslint-disable no-unused-vars*/
  const [
    //states
    { 
      disable, name, title, activities, 
      localconfiguration, open, mode, code, 
      customers, customersFilterChosenRef, loading, pageNumber,
      pageNumberRef, totalPage, page, loadingProcessRef
    }, 
    //setStates
    { 
      setCustomersFilterChosen, SaveActivity 
    },
    //function
    { 
      pageChange,handleClickOpen, handleClose, handleChangeInput, 
      handleChecked,handleChange
    },
  ] = ActivitiesViewModel();

  const classes = useStylesEmployees();

  const handleClickList = (e, isHaveRelation) => {
    if(!isHaveRelation) {
      e.stopPropagation();
    }
  }

 /* eslint-enable array-callback-return*/
  return (
    <div>
      {activities?
        <div style={{marginBottom:'20px'}}>
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{backgroundColor:'#f2f2f6'}}>
            <ListItem>
              <Grid container>
                <Grid item xs={10}>
                  <Typography variant="h5">Name</Typography>
                </Grid>
                <Grid item xs={2} style={{textAlign:'right',paddingRight:'40px'}}>
                  {localconfiguration.activity_settings?
                    <AddButton size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                      <AddIcon />
                    </AddButton>
                  :''
                  }
                </Grid>
              </Grid>
            </ListItem>
          </List>
          {loadingProcessRef.current ?
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
            </Grid>
          :
            <>
              {activities.map((Customer,index) => {
                const isHaveRelation = Customer.customers.length > 0;
                return(
                  <CustomAccordion key={index}>
                    <CustomAccordionSummary
                      id={`activities_list_${index}`}
                      expandIcon={isHaveRelation ? <ExpandMoreIcon /> : ''}
                      style={{ userSelect: 'auto', cursor: isHaveRelation ? 'pointer' : 'auto', alignItems: 'flex-start' }}
                    >
                      <Grid container onClick={(event) => handleClickList(event, isHaveRelation)} onFocus={(event) => event.stopPropagation()}>
                        <GridCustom item xs={6} md={6} lg={6} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                          <Typography noWrap variant="h6" id={`activity_name_${index}`}>{Customer.name}</Typography>
                        </GridCustom>
                        {localconfiguration.activity_settings?
                          <Grid item xs={2} style={{ textAlign: 'right', marginLeft: 'auto', paddingRight: isHaveRelation ? '0px' : '36px' }}>
                            <IconButton id={`edit_activity_${index}`} aria-label="edit" onClick={(event) => { event.stopPropagation(); handleClickOpen(Customer,'edit'); }}>
                              <EditIcon fontSize="small"/>
                            </IconButton>
                            <IconButton id={`delete_activity_${index}`} aria-label="delete" onClick={(event) => { event.stopPropagation(); handleClickOpen(Customer, 'delete'); }} >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Grid>
                        :
                          <Grid item xs={1} style={{ textAlign:'right', marginLeft: 'auto', paddingRight: isHaveRelation ? '0px' : '36px' }}>
                            <IconButton id={`edit_activity_${index}`} aria-label="edit" onClick={(event) => { event.stopPropagation(); handleClickOpen(Customer,'edit'); }}>
                              <EditIcon fontSize="small"/>
                            </IconButton>
                          </Grid>
                        }
                      </Grid>
                    </CustomAccordionSummary>
                    <AccordionDetails>
                      <Grid container key={index} style={{ paddingBottom: '1px' }}>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                          <div>
                            <Grid container key={index}>
                              {isHaveRelation ?
                                <Grid item xs={1} style={{ maxWidth: '8.333333% !important' }}>
                                  <Typography noWrap variant="h6">Customers: &nbsp;</Typography>
                                </Grid>
                              :''}
                              <Grid item xs={11}>
                                {/* eslint-disable-next-line array-callback-return */}
                                {Customer.customers.map((item, index) => {
                                  if (item.activities_id.status === 'published' && item.customers_id !== null) {
                                    return (
                                      <CustomTooltip
                                        key={index}
                                        title={<Typography variant="h6">{item.customers_id.name}</Typography>}
                                      >
                                        <Chip
                                          key={index}
                                          variant="outlined"
                                          size="small"
                                          color="primary"
                                          label={
                                            <Typography variant="h6" id={`customers_${index}`}>
                                              {item.customers_id.code ? item.customers_id.code : item.customers_id.name}
                                            </Typography>
                                          }
                                        />
                                      </CustomTooltip>
                                    );
                                  }
                                })}
                                {/* eslint-enable-next-line array-callback-return */}
                                </Grid>
                              </Grid>
                            </div>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </CustomAccordion>
                );
              })}
              <Dialog
                id="activities_dialog"
                isopen={`${open}`}
                open={open}
                fullWidth={true}
                onClose={handleClose}
              >
                <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="activities_dialog_title" component="div" variant="h6"><h3>{title}</h3></Typography></DialogTitle>
                <DialogContent>
                  {localconfiguration.activity_settings?
                    mode !== 'delete'?
                      <>
                        <TextFieldCustom
                          size='small'
                          id="code_text" 
                          fullWidth
                          label="Code"
                          name="code"
                          variant="outlined"
                          value={code}
                          onChange={handleChangeInput}
                        />
                        <br/>
                        <br/>
                      
                        <TextFieldCustom
                          size='small'
                          id="name_text" 
                          fullWidth
                          label="Name"
                          name="name"
                          variant="outlined"
                          value={name}
                          onChange={handleChangeInput}
                        />
                        <br/>
                        <br/>
                      
                        {customers?
                          <>
                            <AutoCompleteCustom
                              size='small'
                              multiple
                              id="checkboxes-tags-demo"
                              options={customers}
                              disableCloseOnSelect
                              fullWidth={true}
                              getOptionLabel={(option) => option.name}
                              getOptionSelected={(option, value) => option.id === value.id}
                              onChange={(event, newValue) => {
                                setCustomersFilterChosen(newValue)
                              }}
                              value={customersFilterChosenRef.current}
                              renderOption={
                                (option, { selected }) => (
                                  <React.Fragment>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                      {option.name}
                                  </React.Fragment>
                              )}
                              style={{ maxHeight: '250px', overflow:'scroll', paddingTop:'8px', overflowX:'hidden', marginTop: '-5px'}}
                              renderInput={(params) => (
                                <TextFieldCustom2 {...params} variant="outlined" label="Select customers" placeholder="Select..." />
                              )}
                            />
                            <div style={{ display: 'flex' }}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size="small"
                                      style={{ marginRight: 8 }}
                                      value='all'
                                      checked={customersFilterChosenRef.current.length === customers.length}
                                      onChange={handleChecked} 
                                    />}
                                  label="Select All"
                                />
                              </FormGroup>
                            </div>
                          </>                  
                        :
                          <>
                            <Skeleton animation="wave" variant="rounded" height={34} style={{borderRadius:'8px', marginBottom: '16px'}}/>
                            <Skeleton animation="wave" variant="rounded" height={34} style={{borderRadius:'8px', marginBottom: '16px'}}/>
                            <Skeleton animation="wave" variant="rounded" height={34} style={{borderRadius:'8px'}}/>
                          </>
                        }
                      </>
                    :''

                  :

                    customers?
                      <>
                        <AutoCompleteCustom
                          size='small'
                          multiple
                          id="checkboxes-tags-demo"
                          options={customers}
                          disableCloseOnSelect
                          fullWidth={true}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {
                            setCustomersFilterChosen(newValue)
                          }}
                          value={customersFilterChosenRef.current}
                          renderOption={
                            (option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                  {option.name}
                              </React.Fragment>
                          )}
                          style={{ maxHeight: '250px', overflow:'scroll', paddingTop:'8px', overflowX:'hidden', marginTop: '-5px'}}
                          renderInput={(params) => (
                            <TextFieldCustom2 {...params} variant="outlined" label="Select customers" placeholder="Select..." />
                          )}
                        />
                        <div style={{ display: 'flex' }}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  style={{ marginRight: 8 }}
                                  value='all'
                                  checked={customersFilterChosenRef.current.length === customers.length}
                                  onChange={handleChecked} 
                                />}
                              label="Select All"
                            />
                          </FormGroup>
                        </div>
                      </>                  
                    :
                      <Skeleton animation="wave" variant="rounded" height={34} style={{borderRadius:'8px'}}/>
                  }

                </DialogContent>
                <DialogActions>
                  {loading?
                    <CircularProgress className={classes.circular}/>
                  :
                    <div>
                      <CancelButton 
                        id="cancel_btn"
                        size="small" 
                        variant="contained"
                        onClick={handleClose}
                        style={{ marginRight: '5px' }}
                      >
                        Cancel
                      </CancelButton>
                      <SubmitButton
                        id="save_btn"
                        disabled={mode==='delete'?false:disable}
                        size="small" 
                        variant="contained"
                        onClick={()=> SaveActivity()}
                      >
                        {mode==='delete'?'Delete':'Save'}
                      </SubmitButton>
                    </div>
                  }
                </DialogActions>
              </Dialog>
            </>
          }
        </div>
      :
      <>
        <Grid container spacing={0} style={{ marginBottom: '10px'}}>
          <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1}>
            <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
          </CustomGridApproval>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
          </Grid>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
          </Grid>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
          </Grid>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
          </Grid>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
          </Grid>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
          </Grid>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
          </Grid>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rounded" height={35} style={{borderRadius:'8px',marginBottom:'10px'}}/>
          </Grid>
        </Grid>
      </>
      }

      {activities?      
        <Grid 
          item xs={12}
          style={{textAlign:'left',marginBottom:'10px', fontFamily:'Manrope'}}
        >
          <span style={{paddingRight:'20px'}}>
            Rows per page:
          </span>
          <span style={{paddingRight:'20px'}}>
            <TextField
              id="standard-select-currency"
              select
              value={pageNumber}
              onChange={handleChange}
            >
              {pageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </span>

          <span id="page_number" style={{paddingRight:'20px'}}> 
            {(pageNumberRef.current*(page-1))+1} - {(pageNumberRef.current*page)>totalPage? totalPage : pageNumberRef.current*page} of {totalPage}
          </span>

          <Button 
            id="prev_btn"
            variant="outlined" 
            size="small" 
            aria-label='Back Button'
            disabled={page === 1} 
            className={classes.buttonLeft}
            onClick={()=>pageChange(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>

          <Button
            id="next_btn"
            variant="outlined" 
            size="small"
            aria-label='Next Button'
            className={classes.buttonRight}
            disabled={page >= Math.ceil(totalPage / pageNumberRef.current)}
            onClick={()=>pageChange(1)}
          >
            <ArrowForwardIosRoundedIcon />
          </Button>
        </Grid>
      :
      " "}
    </div>
  );
}