import React from 'react';
import {
  TextField,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import EventEmitter from 'src/utils/EventEmitter';
import { useStyles } from '../style.js';

var searchTimeout;

export const Approvals = () => {
  const [searchApprovals, setSearchApprovals] = React.useState(null);
  const classes = useStyles();

  const handleChange = (e) => {
    if (e.target.name === 'searchApprovals') {
      setSearchApprovals(e.target.value);
    }
  }

  const searchApprovalsData = (event) => {
    if (event.key === "Enter") {
      searchApprovalsEmit();
    }
    else {
      clearTimeout(searchTimeout);

      searchTimeout = setTimeout(() => {
        searchApprovalsEmit();
      }, 1000)
    }
  }

  const searchApprovalsEmit = () => {
    EventEmitter.emit('searchApprovals', {
      text: searchApprovals
    })
  }

  return (
    <TextField
      fullWidth
      id="search_approvals"
      variant="outlined"
      size="small"
      className={classes.search}
      name="searchApprovals"
      onChange={handleChange}
      onKeyUp={(e) => searchApprovalsData(e)}
      autoComplete="off"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  )
}