import directus from '../../services/directus';

export const AwardsController = async (data,page) => {
    let filterData;

    if (data) {
      filterData = {
        fields: '*,award_rules.id,award_rules.award_rules_id.code,award_rules.award_rules_id.name,award_rules.value,award_rules.award_rules_id.id,award_rules.id',
        meta: "filter_count",
        limit: localStorage.getItem("awards_page") ? localStorage.getItem("awards_page") : 50,
        page: page,
        filter: {
          'code': { like: data },
          'name': { 'logical': 'or', like: data },
           status : { eq: 'published' },
        },
        sort: "name"
      }
    }
    else {
      filterData = {
        fields: '*,award_rules.id,award_rules.award_rules_id.code,award_rules.award_rules_id.name,award_rules.value,award_rules.award_rules_id.id,award_rules.id',
        meta: "filter_count",
        limit: localStorage.getItem("awards_page") ? localStorage.getItem("awards_page") : 50,
        page: page,
        filter: { 
          status : { eq: 'published' },
        },
        sort: "name"
      };
    }

    var result = await directus.getItems('awards',filterData);
    
    // sort award rules code
    result.data.map((awardlist)=>{
      awardlist.award_rules.sort((a, b) => a.award_rules_id.code > b.award_rules_id.code ? 1 : -1);
    })
  
    return result;
};

export const PayrunListController = async () => {
  let payrunResult = [];
  var payrun_data = await directus.getField("awards", "payrun");
  const payrun_list = Object.entries(payrun_data.data.options.choices);
  payrun_list.map((data, index) => {
    payrunResult[index] = { id: data[0], name: data[1], value: data[0] };
  })  

  return payrunResult;
};


export const AwardRulesController = async () => {
  var result = await directus.getItems('award_rules', {
    fields: '*',
    limit: -1,
    sort: "code"
  });
return result;
};

