import { makeStyles } from '@material-ui/core';
import {
  withStyles,
  Button,
  Tooltip,
  Chip,
  Badge,
  TextField,
  Switch
} from '@material-ui/core';
import MuiListItem from "@material-ui/core/ListItem";
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export const CustomSwitch = withStyles(() => ({
  root:{
    width: 32,
    height: 20,
    padding: 0,
    marginLeft: '10px',
    marginRight: '10px',
    "& .MuiSwitch-switchBase": {
      padding: 0,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
  
        "& + .MuiSwitch-track": {
          backgroundColor: "white",
          opacity: 1,
          boxSizing: "border-box",
          border: "1px solid rgba(0, 0, 0, 0.1)"
        }
      }
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 14,
      height: 14,
      margin: "3px 2px 3px 0px",
      color: "#5DB6FF"
    },
    "& .MuiSwitch-track": {
      borderRadius: "10px",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      backgroundColor: "#958d8d",
      padding: 0
    }
  }
}))(Switch);

export const SendResetPWButton = withStyles({
  root: {
    borderRadius: '8px',
    '&:hover': {
      borderRadius: '8px',
    },
  },
})(Button);

export const TextFieldCustom = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      height: '34px',
      fontFamily: "Manrope",
    },
    '& .MuiFormLabel-root': {
      top:'-3px',
    },
    '& input': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    }
  },
})(TextField);

export const FormControlCustom = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      height: '34px',
      fontFamily: "Manrope",
    },
    '& .MuiFormLabel-root': {
      top:'-3px',
    },
    '& input': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    }
  },
})(FormControl);

export const SubmitButton = withStyles(() => ({
  root: {
    borderRadius: '8px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  }
}))(Button);

export const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "16px",
    maxWidth: 500,
    backgroundColor: "grey"
  }
})(Tooltip);

export const ApplyButton = withStyles(() => ({
  root: {
    borderRadius: '8px',
    textTransform: 'none',
    backgroundColor: ' #7E8B95',
    color: '#ffffff',
    height: '24px',
    '&:hover': {
      backgroundColor: '#7E8B95',
    },
  },
}))(Button);

export const ExportButton = withStyles(() => ({
  root: {
    '&.MuiLoadingButton-root': {
      borderRadius: '8px !important',
      textTransform: 'none',
      backgroundColor: '#28699a !important',
      color: '#ffffff',
      height: '24px',
      marginBottom: '10px !important',
      '&:hover': {
        backgroundColor: '#28699a',
      },
    }
  },
}))(LoadingButton);

export const AddQuickFilter = withStyles(() => ({
  root: {
    '&.MuiLoadingButton-root': {
      borderRadius: '8px !important',
      textTransform: 'none',
      backgroundColor: 'rgb(93, 182, 255) !important',
      color: '#ffffff',
      height: '24px',
      marginBottom: '10px !important',
      '&:hover': {
        backgroundColor: 'rgb(93, 182, 255)',
      },
    }
  },
}))(LoadingButton);

export const CancelQuickFilter = withStyles(() => ({
  root: {
    marginRight: '5px',
    marginTop: '10px',
    borderRadius: '8px',
    textTransform: 'none',
    backgroundColor: '#F2F2F6',
    color: '#374355',
    height: '24px',
    '&:hover': {
      backgroundColor: '#F2F2F6',
    },
  },
}))(Button);

export const FilterButton = withStyles(() => ({
  root: {
    marginRight: '5px',
    marginTop: '10px',
    borderRadius: '8px',
    textTransform: 'none',
    backgroundColor: '#F2F2F6',
    color: '#374355',
    height: '24px',
    '&:hover': {
      backgroundColor: '#F2F2F6',
    },
  },
}))(Button);

export const SaveFilterButton = withStyles(() => ({
  root: {
    marginLeft: '3px',
    marginTop: '10px',
    borderRadius: '8px',
    textTransform: 'none',
    backgroundColor: ' #F2F2F6',
    color: '#374355',
    height: '24px',
    width: '125px',
    '&:hover': {
      backgroundColor: '#F2F2F6',
    },
  },
}))(Button);

export const EditQuickFilter = withStyles(() => ({
  root: {
    '&.MuiLoadingButton-root': {
    marginRight: '5px',
    marginTop: '10px',
    borderRadius: '8px',
    textTransform: 'none',
    backgroundColor: '#F2F2F6',
    color: '#374355',
    height: '24px',
    '&:hover': {
      backgroundColor: '#F2F2F6',
    },
  },
  },
}))(LoadingButton);


export const QuickFiltersFormControlLabel = withStyles(() => ({
  root: {
    margin:'0px 0px 10px 0px !important'
  },
}))(FormControlLabel);

export const QuickFiltersChip = withStyles(() => ({
  root: {
    backgroundColor: '#5DB6FF',
    color: '#FFFFFF',
    width:'16vw',
    height:'24px',
    borderRadius:'8px', 
  },
}))(Chip);


export const CancelButton = withStyles({
  root: {
    borderRadius: '8px',
    marginRight: '4px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);

export const CustomChip = withStyles(() => ({
  root: {
    backgroundColor: '#5DB6FF',
    color: '#FFFFFF',
    marginLeft: '10px !important',
  },
}))(Chip);

export const CustomBadge = withStyles(() => ({
  root: {
    '& .MuiBadge-anchorOriginTopRightRectangular': {
      right: '11px',

    }
  },
}))(Badge);

export const ListItem = withStyles({
  root: {
    display: 'flex',
    paddingTop: 5,
    paddingBottom: 5,
    color: '#333942',
    "&$selected": {
      backgroundColor: "#39F",
      color: 'white !important',
    },
    "&$selected:hover": {
      backgroundColor: "#39F",
    },
  },
  selected: {}
})(MuiListItem);

export const FileChip = withStyles(() => ({
  root: {
    backgroundColor: '#8bc1e0',
    color: 'white',
    marginRight: '3px',
    '& .MuiSvgIcon-root': {
      color: 'white'
    },
    '&:hover': {
      backgroundColor: '#8bc1e0',
    },
    '&:focus': {
      backgroundColor: '#8bc1e0',
    }
  },
}))(Chip);

export const useStyles = makeStyles(() => ({
  root: {
    // backgroundColor: '#ffffff !important',
    // height:'80px',
  },
  iconFilter: {
    width: '40px',
    height: '40px',
    borderRadius: '8px !important',
    backgroundColor: '#F2F2F6',
    margin: '0px 10px',
  },

  logo: {
    width: '133px',
    marginLeft: '25px',
    marginRight: '36.67px'
  },
  logo1: {
    height: '40px',
    marginLeft: '25px',
    marginRight: '36.67px'
  },
  toolbar: {
    paddingLeft: '0px !important',
    minHeight: '0px !important',
    margin: '20px 0px 20px 0px !important',
    alignSelf: 'stretch',
    '@media print': {
      display:'none !important'
    },
  },
  avatar: {
    cursor: 'pointer',
    margin: '0px 0px 0px 10px'
  },
  search: {
    paddingRight: '10px !important',
    width: '100%',
    height: '40px',
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      borderRadius: '8px',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      '& fieldset': {
        border: '0px',
      },
    },
  },
  linear: {
    color: '#FA9917',
    marginTop: '10px',
    marginBottom: '10px'
  },
  title: {
    flexGrow: 1,
    color: 'black',
    paddingLeft: '40px',
    fontSize: '22px'
  },
  style: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
  circular: {
    color: '#FA9917',
  },
  circularProfile: {
    color: '#FA9917',
    margin: '25px'
  },

  topbarTab: {
    height: "40px",
    minWidth: 'max-content',
    borderRadius: "8px",
    background: "rgba(242, 242, 246, 0.5)",
    border: "1px solid #F2F2F6",
    cursor: "pointer",
    color: '#374355',
    lineHeight: "36px",
    textAlign: "center",
    marginRight: "10px",
    fontFamily: 'Manrope',
    fontWeight: '400',
    fontSize: 18,
    letterSpacing: '-0.06px',
    "&:hover": {
      color: "#5DB6FF",
      border: "1px solid #5DB6FF",
      borderRadius: "8px",
    }
  },
  menuTitle: {
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '8px',
  },

  selectedTab: {
    background: 'rgba(212, 227, 242, 0.5)',
    fontWeight: 'bold',
    "&:hover": {
      color: "#5DB6FF",
      border: "1px solid #5DB6FF",
    }
  },

  add: {
    width: '40px',
    height: '40px',
    backgroundColor: '#5DB6FF',
    margin: '0px 20px 0px 20px'
  },
  iconAdd: {
    backgroundColor: '#5DB6FF',
    color: '#FFFFFF',
    borderRadius: '19px',
    fontSize: "40px"
  },
}));

