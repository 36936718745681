import React, { useState, useEffect } from 'react';
import { 
  controlLoadEmployee,
  controlLoadCustomers,
 } from '../../Controller/Settings/Customers.js';
import directus from '../../services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import useStateRef from "react-usestateref";
import linq from "linq";
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../views/Components/ErrorMessage';

let page = 1;

export const CustomersViewModel = () => {
  /* eslint-disable no-unused-vars*/
  const [initialized, setInitialized] = useState(false);
  const [Customers, setCustomers] = useState(null);
  const [customer, setCustomer] = useState(null);

  const [employees, setEmployees] = useState(null);
  const [employeesFilterChosen, setEmployeesFilterChosen, employeesFilterChosenRef] = useStateRef([]);
  const [searchCus, setSearchCus] = useState('');

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = React.useState(null);

  const [totalPage, setTotalPage]= useState('');
  const [pageNumber, setPageNumber,pageNumberRef] = useStateRef(localStorage.getItem("customers_page")?localStorage.getItem("customers_page"):50);
  const [mode, setMode] = useState('');

  const [name, setName] = React.useState('');
  const [code, setCode] = React.useState('');
  const [CustomerData, setCustomerData] = useState(null);
  const [loadingProcess, setLoadingProcess, loadingProcessRef] = useStateRef(false);
  
  const { enqueueSnackbar } = useSnackbar();
  /* eslint-enable no-unused-vars*/

  let disable = name === '';
  let localconfiguration = JSON.parse(window.localStorage.getItem('configuration'));
  
  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadCustomers();
    }

    const searchCustomer = (eventData) => {
      setLoadingProcess(true);
      page = 1;
      setSearchCus(eventData.text);
      loadCustomers(eventData.text);
    }

    const listener = EventEmitter.addListener('searchCustomer', searchCustomer);

    return () => {
      listener.remove();
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

   /* eslint-disable array-callback-return*/
const loadCustomers = async (data) => {
  try
  {
    var CustomersResults = await controlLoadCustomers(data,page);

    CustomersResults.data.map((customerResult,x)=> {
      customerResult.managers.map((managerResult,y) => {
        if(managerResult.employee === null)
        {
          CustomersResults.data[x].managers.splice(y, 1);
        }
      })
    })
    

  }
    catch(e)
    {
      enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
    }

    setCustomers(CustomersResults.data);
    setTotalPage(CustomersResults.meta.filter_count);
    loadEmployee();
  };

  const loadEmployee = async () => {
    try
    {
      var EmployeesResults = await controlLoadEmployee();

      setEmployees(EmployeesResults.data);
    }
    catch(e)
    {
      enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
    }
    
    setLoadingProcess(false);
  };

  const pageChange = (value) => {
    setLoadingProcess(true);
    page = page + value;
    loadCustomers(searchCus);
  }

  const handleChange = (event) => {
    setLoadingProcess(true);
    page=1;
    setPageNumber(event.target.value);
    localStorage.setItem("customers_page", JSON.parse(event.target.value));
    loadCustomers(searchCus);
  };

  const handleClickOpen = (Customer, mode) => {
    setOpen(true);

    if(mode === 'edit')
    {
      setMode('edit');
      setName(Customer.name);
      setCode(Customer.code);
      setCustomerData(Customer);

      if(Customer.managers.length > 0)
      {
        let result = [];
        Customer.managers.map(data => {
          if (data.employee) {
            result = [...result, data.employee];
          }
        })
  
        setEmployeesFilterChosen(result);
      }
      else
      {setEmployeesFilterChosen([]);}
  
      setCustomer(Customer);
      setTitle(Customer.name);
    }
    else if(mode === 'add')
    {
      setMode('add');
      setCustomerData('');
      setName('');
      setCode('');
      setCustomer('');
      setTitle('Add Customer');
      setEmployeesFilterChosen([]);
    }
    else
    {
      setMode('delete');
      setName(Customer.name);
      setCode(Customer.code);
      setCustomerData(Customer);
      setTitle('Delete '+Customer.name);
    }

  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeInput = (e) => {
    const {name,value} = e.target;
    if(name==='name')
    {
      setName(value);
    }
    else
    {
      setCode(value);
    }
  }

  const SaveCustomer = async () => {
    setLoading(true);
    setLoadingProcess(true);

    let createData = [];
    let merge = '';
    if(mode === 'edit')
    {
      employeesFilterChosenRef.current.map(data => {
        const filterInput = linq.from(customer.managers)
          .where(x=>x.employee.id===data.id).toArray();
          if(filterInput.length === 0)
          {
            merge = [...merge, data.id];
            createData = [...createData, {employee:data.id, customer:customer.id, status:'published'}];
          }
      })
  
      if(createData.length > 0)
      {
        try
        {
          await directus.createItems('customers_employees', createData);
        }
        catch(e)
        {
          enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
        }
      }
  
      // DELETE ===============================================================================================================
      let deleteData = '';
      
      customer.managers.map(customer => {
        const filterInput = linq.from(employeesFilterChosenRef.current)
          .where(x=>x.id===customer.employee.id).toArray();
        if(filterInput.length === 0)
        {
          merge = [...merge, customer.employee.id];
          if(deleteData === '')
          {
            deleteData = customer.employee.id;
          }
          else
          {
            deleteData = customer.employee.id + ',' + deleteData;
          }
        }
      })
  
      let deleteInput = [];
      var CustomerEmployeeResult;
  
      if(deleteData !== '')
      {
        try
        {
          CustomerEmployeeResult = await directus.getItems('customers_employees', {
            fields: '*',
            filter: {
              customer: { eq: customer.id },
              employee: { in: deleteData }
            }
          });
        }
        catch(e)
        {
          enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
        }
  
        CustomerEmployeeResult.data.map(data=>{
          deleteInput = [...deleteInput, data.id]
        })
  
        try
        {
          await directus.deleteItems('customers_employees', deleteInput);
        }
        catch(e)
        {
          enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
        }
      }
  
      try
      {
        await directus.updateItem('customers', CustomerData.id, {name: name,code:code});
      }
      catch(e)
      {
        enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
      }

    }
    else if(mode === 'add')
    {
      let employeeData = [];

      employeesFilterChosenRef.current.map(employee => {
        employeeData = [...employeeData, {"employee": { "id": employee.id}}];
      })
 
      try
      {
        await directus.createItems('customers',{
          code: code,
          name: name,
          status: 'published',
          managers : employeeData
        });
      }
      catch(e)
      {
        enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
      }
    }
    else
    {
      try
      {
        await directus.deleteItem('customers', CustomerData.id);  
      }
      catch(e)
      {
        enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
      }
    }

    loadCustomers(searchCus?searchCus:'');
    setLoading(false);
    handleClose();

  }

  return[
    //states
    {
      employees, localconfiguration, open, title, 
      mode, employeesFilterChosenRef, loading, disable,
      pageNumberRef, totalPage, page, code,
      pageNumber, name, Customers, loadingProcessRef
    },
    //setStates
    {
      setEmployeesFilterChosen, 
    },
    //function
    {
      handleClickOpen, handleClose, SaveCustomer,
      handleChange, handleChangeInput, pageChange
    }
  ];
}

export default CustomersViewModel;